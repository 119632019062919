import { FaUserAlt } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';

const MarketerDetails = ({ marketer,user }) => {
	useEffect(()=>{
		return
	},[marketer])
	return (
		<div className='verification-marketer'>
			<div className='verification-marketer-icon-holder'>
				<h3>
					<FaUserAlt />
				</h3>
			</div>

			{  marketer &&
				<div className='verification-marketer-details'>
				<h4>{marketer.marketer || "No Marketer Details"}</h4>
				{ marketer.country=='Kenya' && user.isAuth && <h5>0{marketer.markCont}</h5>}
				{ marketer.country=='Kenya' && !user.isAuth && <h5>Contact Hidden</h5>}
				{ marketer.country=='Uganda' && <h5>No contact information</h5>}
			</div>}
		</div>
	);
};

export default MarketerDetails;
