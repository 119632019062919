import React, { useState, useContext, useEffect } from "react";
import {useHistory} from 'react-router-dom'
import NavItem from "./Navbar-Item";
import logo from "../../images/small1.png";
import { BiUser } from "react-icons/bi";
import "./Navbar.css";
import { FaWindowClose } from "react-icons/fa";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { ElevatorSharp } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Logout from '@mui/icons-material/Logout';
const Navbar = ({user}) => {
	let navigate=useHistory()
	const [othercounty,setothercounty]=useState(null)
	const [showmenu, setshowmenu] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
event.stopPropagation()  
  setAnchorEl(null);
  };
	function logout() {
		window.location.href = "/logout";
	}
	const [currentCounty, setcurrentCounty] = useState(null);
	function handleMap() {
		window.location.href = "/mymap";
	}
	function Switch(county) {
		let body={
			switchto:county
		}
		fetch("/api/v1/switchcounty",{method:'POST',credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(body)}).then(result=>result.json()).then(result=>{
			if(result.done){
				window.location.href='/'
			}else{
return
			}
		})
	}
	function showMenu() {
		setshowmenu(!showmenu);
	}
	useEffect(() => {
		if(user.isAuth){
			let x=user.user.county.filter((county) => {
				return county != user.user.currentCounty;
			})
			setothercounty(x)
		}
	}, []);
	return (
		<div className='navbar'>
			<div className='n-logo'>
				<img src={logo} alt='' />
			</div>
			<div className='n-items'>
				<NavItem name={"Home"} link={"/"} />
				<NavItem name={"Data Analysis"} link={"Analysis"} />
				{!user.isAuth &&
				<>
						<div onClick={(e) => handleMap()} className='nav-item'>
							Map
						</div>
						<NavItem name={"Explore Toilets"} link={"Verification"} />
				</>
					}
				{user.isAuth && (
					<>
						{/* <div onClick={(e) => handleMap()} className='nav-item'>
							Map
						</div> */}
						<NavItem name={"Data Verification"} link={"Verification"} />
						{/* <NavItem name={"Enter Data"} link={"indicatorreporting"} /> */}
						<NavItem name={"Downloads"} link={"Downloads"} />
					</>
				)}
			</div>
			<div className='user-logo' onClick={(e) => handleClick(e)}>
				<div>
					<h2>
						<BiUser />
					</h2>
				</div>
				{/* {user && user.isAuth &&   */}
					<React.Fragment>
					<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					PaperProps={{
					  elevation: 0,
					  sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
						  width: 32,
						  height: 32,
						  ml: -0.5,
						  mr: 1,
						},
						'&:before': {
						  content: '""',
						  display: 'block',
						  position: 'absolute',
						  top: 0,
						  right: 14,
						  width: 10,
						  height: 10,
						  bgcolor: 'background.paper',
						  transform: 'translateY(-50%) rotate(45deg)',
						  zIndex: 0,
						},
					  },
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				  >
					{/* <MenuItem onClick={handleClose} sx={{fontFamily:'Cera Pro2',fontSize:'13px'}}>
					  <Avatar /> My Dashboard
					</MenuItem> */}
					<Divider />
					{/* <MenuItem onClick={handleClose}>
					  <ListItemIcon>
						<PersonAdd fontSize="small" />
					  </ListItemIcon>
					  Add another account
					</MenuItem> */}
					{user.isAuth && <MenuItem onClick={handleClose}  sx={{fontFamily:'Cera Pro2',fontSize:'13px'}}>
					  Current County: {user.user.currentCounty}
					</MenuItem>}
					{othercounty && othercounty.length>0 && othercounty.map((county,index)=>(
						<MenuItem onClick={e=>{
												Switch(county)}} key={index} sx={{fontFamily:'Cera Pro2',fontSize:'13px'}}>
						Switch to: {county}
					  </MenuItem>
					))}
					<MenuItem onClick={logout}  sx={{fontFamily:'Cera Pro2',fontSize:'13px'}}>
					  <ListItemIcon>
						<Logout fontSize="small" />
					  </ListItemIcon>
					  Logout
					</MenuItem>
				  </Menu>
				</React.Fragment>
				{/* } */}
			</div>
		</div>
	);
};

export default Navbar;
