import MarketerDetails from "./MarketerDetails";
import { FcNext } from "react-icons/fc";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineMap } from "react-icons/hi";
import { GiPayMoney } from "react-icons/gi";
import { MdOutlineCleanHands } from "react-icons/md";
import { FaExclamation } from "react-icons/fa";
import { BsCheckLg,BsImages } from "react-icons/bs";
function redirect(){
window.location.href='http://localhost:3001/signin'
}
const VerificationMarketer = ({marketer,marketerclick,handleSkip,handleReveal,system,handleMap,handleSan,user}) => {    
    return ( 
        <div className="marketer-details-container"> 
        <div className="marketer-details" onClick={(e)=>marketerclick(marketer.markCont || marketer.marketer)}>
        <MarketerDetails marketer={marketer} user={user}/>
        </div>
        <div className="skip-system">
            <div className="skip-container" onClick={e=>handleMap(marketer._id)}>
                 <h1 className="skip-button"><HiOutlineMap/></h1>
            </div>
           {user.isAuth && <div className="skip-container" onClick={e=>handleReveal(marketer._id,'similar')}>
                
                 <h1 className="skip-button"><BsImages/></h1>
            </div>}
            {/* <div className="skip-container" onClick={e=>handleSan(marketer._id) }>
                {marketer.hygieneImage && <div className="san-indicator">
                      <h1><BsCheckLg className="san-indicator-icon"/></h1>
                 </div>}
                 <h1 className="skip-button"><MdOutlineCleanHands/></h1>
            </div> */}
            
               {user.isAuth && <div className="skip-container" onClick={e=>handleReveal(marketer._id,'edit')}>
               {marketer.approval=="Action Needed" && <div className="danger-indicator">
                      <h1><FaExclamation className="danger-indicator-icon"/></h1>
                 </div>}
                         <h1 className="skip-button"><FiEdit3/></h1>
                    </div>}
           
            
        </div>
        </div>
     );
}
 
export default VerificationMarketer;