import React, { useState, useEffect,useContext } from 'react';
import { filtersContext } from './Analyses';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
const Callout=()=>{
    const {filters,setfilters}=useContext(filtersContext)
    const [callout,setcallout]=useState(null)
const [fetching,setfetching]=useState(false)
    const [cost,setcost]=useState(null)
    const controller = new AbortController()
const signal = controller.signal
function abortFetching() {
  controller.abort()
}
    function fetchCallout(){
        setfetching(true)
        fetch('/api/v1/calloutvalue',{method:'POST',credentials:'include',body:JSON.stringify(filters),headers:{"Content-Type":"application/json"}})
        .then(res=>res.json())
        .then(res=>{
            if(!res.total){
                setcallout(null)
                setfetching(false)
            }else{
                setcallout(numberWithCommas(res.total))
                setfetching(false)
            }
        })
    }
    function fetchCost(){
        fetch('/api/v1/averagecost',{method:'POST',credentials:'include',body:JSON.stringify(filters),headers:{"Content-Type":"application/json"}})
        .then(res=>res.json())
        .then(res=>{
            setcost(numberWithCommas(res.total))
        })
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    useEffect(()=>{
        fetchCallout()
        fetchCost()
    },[])
    useEffect(()=>{
        fetchCost()
        fetchCallout()
    },[filters])
    return(
        <div>
            <h5>Total Toilets</h5>
            {callout && !fetching &&
            <h1 className='calloutvalue'>{callout}</h1>
            }
            { !callout && !fetching && 
                <div className='nograph'>
                  <Alert severity="warning">The selected filters did not return any data</Alert>
                </div>
                }
            {fetching &&
             <CircularProgress />
            }
        </div>
    )
}
export default Callout