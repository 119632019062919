import { useEffect,useState } from 'react'
import setup from '../../images/setup.png'
import logo from '../../images/small1.png'
import { AiOutlineDown } from "react-icons/ai";
import './usersetup.css'
const UserSetup = () => {
    let rolesarray=["Country Coordinator","Project Manager","Project Officer","M&E Officer","Project Assistant","Technical Assistant","Communications Officer"]
    let actualrole;
    const [role, setrole] = useState(null)
    const [county, setcounty] = useState(null)
    const [showCounty, setshowCounty] = useState(false)
    const [showRoles, setshowRoles] = useState(false)

    function checkRole(){
        
    }
    useEffect(()=>{
        
    },[role])
    return ( 
        <div className="usersetup-body">
            <div className="notice">
                <div className="notice-image">
                    <img src={setup}alt="" />
                </div>
                <div className="notice-text">
                    <h1>Let’s set you up.</h1>
                    <h2>
                    this is a one time process that will help taylor your experience while using the tool.
                    </h2>
                </div>
            </div>
            <div className="information-card">
                <div className="information-logo">
                    <img src={logo} alt="" />
                </div>
                <div className="information">
                    <div className="informationdiv">
                        <h4 onClick={e=>setshowRoles(true)}>I am a  <AiOutlineDown className='menu-logo'/></h4>
                    </div>
                    {showRoles && <div className='contentdiv'>
                            {rolesarray.map(role=>(
                                <h5 key={role}>{role}</h5>
                            ))}
                        </div>}
                    {showCounty && <div className="informationdiv">
                    <h4>My county of operation is.</h4>                        
                    </div>}
                </div>
                <div className="submit">
                    <button>Lets Go</button>
                </div>
            </div>
        </div>
     );
}
 
export default UserSetup;