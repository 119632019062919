import bin from '../../images/empty.png'
import './Nosystem.css'
const NoSystem = () => {
    return ( 
        <div>
            <div className="no-system">
                <div className="nosystem-container">
                    <div className="nosystem-image">
                        <img src={bin} alt="" />
                    </div>
                    <div className="nosystem-text">
                        <h4>Stage is Empty. No more systems in the Not Verified Category</h4>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default NoSystem;