import type {LayerProps} from 'react-map-gl';
const counties=['Kitui','Nakuru','Embu','Homabay','Siaya','Tharaka','Meru','Migori']
export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'facilities',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
  }
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'facilities',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};

export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'facilities',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#11b4da',
    'circle-radius': 4,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
};
export const countiesLayer: LayerProps = {
    id: 'counties',
    type: 'fill',
    source: 'counties',
    "source-layer": "counties-8t371y",
    paint: {
        "fill-color": "#90EE90",
        // "fill-opacity": 0.5,
        'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.5
        ]
    },
    filter: [
        "match",
        ["get", "COUNTY"],
        counties,
        true,
        false
    ]
  };