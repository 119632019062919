// import Backdrop from '../../../../Backdrop'
import { AiOutlineDown } from "react-icons/ai";
import { useEffect ,useState} from 'react';
import { FcCheckmark } from "react-icons/fc";


const Modal = ({close,approval,sysid,syschange}) => {
    let action;
    let systempending;
    let systemrejecting;
    let postroute;
    let pendreasons=["Images are non Communicative","Toilet requires futher scrutiny","The images are similar to a previous toilet","Other Reason"]
    let rejectreasons=["Toilet has been previously reported","Toilet does not meet qialification criteria ","Images do not match the reported toilet","Other Reason"]
    const [waiting,setwaiting]=useState(false)
    const [reasons, setreasons] = useState(null)
    const [warningMessage, setwarningMessage] = useState(false)
    const [selectedReason, setselectedReason] = useState(null)
    const [showReasons, setshowReasons] = useState(false)
    const [showOther, setshowOther] = useState(false)
    if(approval=="Pending"){
        action='Pend Toilet'
        systempending=true
        postroute="pendsystem"
    }else if(approval=="Rejecting"){
        action="Reject Toilet"
        systemrejecting=true
        postroute="rejectsystem"
    }
    function confirm() {
        if(!selectedReason){
            setwarningMessage(true)
            setshowReasons(false)
            setshowOther(false)
        }else{
            setwaiting(true)
		    localStorage.setItem('Images',JSON.stringify([]))
            let postbody={reason:selectedReason}   
            fetch('/api/v1/'+postroute+'/'+sysid,
            {method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            credentials:'include',
            body:JSON.stringify(postbody)
        }).then(res=>res.json()).then(res=>{
                if(res.updated){
                    syschange()
                    close()
                }
            })

        }

    }
    function handleReasonClick(index){
        setwarningMessage(false)
        let selectedreason=reasons[index]
        if(selectedreason=="Other Reason"){
            setshowReasons(false)
            setselectedReason(false)
            setshowOther(true)
        }
        else{
            setshowOther(false)
            setshowReasons(false)
            setselectedReason(selectedreason)
        }
    }
    useEffect(()=>{
        switch(approval){
            case "Pending":
                setreasons(pendreasons)
                break;
            case "Rejecting":
                setreasons(rejectreasons)
                break;
            default:
                break;
        }
    },[])
    useEffect(()=>{
        return
    },[selectedReason])
    return ( 
        <div className="modal">
            <div className="modal-heading">
                <h3>You are {approval} this system</h3>
            </div>
            <div className="modal-body">
                <div className='pend-reasons' onClick={e=>{setshowReasons(!showReasons); setwarningMessage(false)}}>
                    {!selectedReason && <h5>Select Reason <AiOutlineDown className='menu-logo'/></h5>}
                    {selectedReason && <h5>Reason Selected <FcCheckmark /></h5>}
                </div>
                {showReasons && <div className="p-reasons">
                    {reasons && reasons.map(reason=>(
                        <div className="reason" key={reasons.indexOf(reason)} onClick={e=>handleReasonClick(reasons.indexOf(reason))}>{reason}</div>
                    ))}
                </div>}
                    {showOther && 
                    <div className="other-reason">
                        <input type="text" className="search-bar-inp" placeholder={`Other Reason for ${approval}` } onChange={(e) => setselectedReason(e.target.value)}/>
                        </div>}
                {warningMessage && <div className='warning-select'>
                    <h2>No reason selected</h2>
                    <h3>Reason for {approval} cannot be empty. Kindly select a reason to proceed.</h3>
                    </div>}
                    
            </div>
            <div className="modal-actions">
                {!waiting && 
                <>
                <div className='cancel-button' onClick={e=>close()}><h5>Cancel</h5></div>
                <div className='confirm-button' onClick={e=>confirm()}><h5>{action}</h5></div>
                </>
                }
                {waiting &&
                <>
                <div className='modal-button-waiting1' ><h5>Cancel</h5></div>
                <div className='modal-button-waiting2' ><h5>{action}</h5></div>
                </>
                }
            </div>
        </div>
     );
}
 
export default Modal;