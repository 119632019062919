import React, { useState, useEffect,useMemo } from 'react';
import ReactMapGL,{Marker,Map, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Navbar from '../Navbar/Navbar';
import {clusterLayer, clusterCountLayer, unclusteredPointLayer,countiesLayer} from './layers.ts';


const Dashboard = ({isAuth,user}) => {
const [viewport, setViewport] = useState({
		latitude: -0.89,
		longitude: 37,
		zoom: 7,
		width: '100vw',
		height: '100vh',
	});
	const [month,setmonth]=useState('January')
let somepoints;
const [points,setpoints]=useState(JSON.parse(localStorage.getItem('points'))||null)
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
function getmapdetails(){
	fetch('/api/v1/dashboard',{credentials:'include',headers:{'Content-Type':'application/json'}}).then(data=>{
		return data.json()
	}).then(data=>{
		somepoints=data.map
		return setpoints(data.map)
	})
}
function filterPoints(){
	let newpoints=points
	setpoints(null)
	console.log(newpoints);
	let thepoints=newpoints.features.filter(points=>{
	return points.properties.month=='March'
	})
	newpoints.features=thepoints
	console.log(newpoints);
	return setpoints(newpoints)
	
}
useEffect(()=>{
	getmapdetails()
},[])
useEffect(()=>{
	localStorage.setItem('points',JSON.stringify(points))
},[points])
	useEffect(() => {
		return;
	}, [viewport]);
	return (
		<div className='map-div'>
			<Navbar isAuth={isAuth} user={user}/>
			<ReactMapGL
				{...viewport}
				mapboxAccessToken={
					'pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw'
				}
				mapStyle='mapbox://styles/mapbox/dark-v10'
				onLoad={(viewport) => {
					setViewport(viewport);
				}}
				>
              <Source
          id="counties"
          type="vector"
          url="mapbox://miscuit.a3o6pjkk"
        //  promoteId= {
        //             "counties-8t371y": "COUNTY"
        //         }
        >
            <Layer {...countiesLayer} />
        </Source>      
                <Source
          id="facilities"
          type="geojson"
          data={points}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        > 
            <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
         </Source>
			</ReactMapGL>
		</div>
	);
};

export default Dashboard;
