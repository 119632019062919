import React, { useState, useEffect,useContext,useMemo } from 'react';
import { filtersContext } from './Analyses';
import { AxisOptions, Chart } from "react-charts";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
const ToiletCost=()=>{
//     let data=[{label:'Kisumu',data:[{month:'January',toilets:20},{month:'March',toilets:450},{month:'February',toilets:78}]
//         },{label:'Nakuru',data:[{month:'January',toilets:50},{month:'March',toilets:650},{month:'February',toilets:788}]
//     }
// ]
const controller = new AbortController()
const signal = controller.signal
function abortFetching() {
  controller.abort()
}
const [data,setdata]=useState(null)
const {filters,setfilters}=useContext(filtersContext)
const [element,setelement]=useState('bar')
const [fetching,setfetching]=useState(false)
const [aggregate,setaggregate]=useState(true)
function fetchGraph(){
  setfetching(true)
    fetch('/api/v1/averagecost',{method:'POST',signal:signal,credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(filters)})
    .then(data=>data.json())
    .then(toilets=>{
      if(toilets.data.length<1){
        setdata(null)
  setfetching(false)
      }else{
        setdata(toilets.data)
        setfetching(false)
      }
    })
}
const primaryAxis = useMemo(
    () => ({
      getValue: datum => datum.label,
    }),
    []
  )
  const secondaryAxes = useMemo(
    ()=> [
      {
        getValue: datum => datum.averageCost,
        elementType: element,
        secondaryAxisType:'time',
        secondaryAxisShow : false
      },
    ],
    []
  )
  useEffect(() => {
    fetchGraph()
  }, [])
  useEffect(() => {
    fetchGraph()
  }, [filters])
  useEffect(() => {
    fetchGraph()
  }, [aggregate])
  useEffect(() => {
    fetchGraph()

  }, [element])
  
    return(
        <div className='theprogress'>
            <div className='progresscontrols'>
            <h5>Average toilet cost per toilet type</h5>
            </div>
            <div className='theprogressgraph'>
            {data && !fetching && <Chart
                        options={{
                    data,
                    primaryAxis,
                    secondaryAxes,
                }}
                />}
                { !data && !fetching && 
                <div className='nograph'>
                  <Alert severity="warning">The selected filters did not return any data</Alert>
                </div>
                }
                { fetching && 
                <div className='nograph'>
                  <CircularProgress />
                </div>
                }
                 </div>
        </div>
    )
}
export default ToiletCost