import React, { useState, useEffect,useContext } from 'react';
import Navbar from '../../containers/Navbar/Navbar';
import { filtersContext } from "../Analyses/Analyses";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './EnterData.css'
const FeedData=({user})=>{
let months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
const [active,setactive]=useState(false)
const [month,setmonth]=useState(null)
const [pwd,setpwd]=useState(null)
const [elderlyHH,setelderlyHH]=useState(null)
const [visitedHH,setvisitedHH]=useState(null)
const [meetagenda,setmeetagenda]=useState(null)
const [policymakers,setpolicymakers]=useState({male:null,female:null,pwd:null})
const [gstaff,setgstaff]=useState({male:null,female:null,pwd:null})
const [hhvisited,sethhvisited]=useState(null)
const [awareness,setawareness]=useState(null)
const [ppawareness,setppawarenesss]=useState({male:null,female:null,pwd:null})
const [smcapacity,setsmcapacity]=useState({male:null,female:null,pwd:null})
const [bustrained,setbustrained]=useState({male:null,female:null,pwd:null})
const [rmeeting,setrmeeting]=useState({artisans:null,marketers:null,government:null})
function getMonthDetails(){
    let body={month:month}
    fetch('/api/v1/getExtraCountyDetails',{method:'POST',credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(body)}).then(details=>details.json()).then(details=>{
        console.log(details);
        if(!details.done){
            setactive(false)
            alert('The selected month eithrt contains unverified toilets or hasnt been reported on yet, Kindly check before returning to this menu.')
        }else{
            setactive(true)
            setpwd(details.pwd)
            setvisitedHH(details.visits)
        }
    })
}
useEffect(()=>{
    getMonthDetails()
},[month])
function updatePolicyMakers(e,update){
    let current={...policymakers}
    if(update=='male'){
        current.male=e.target.value
    }
    if(update=='female'){
        current.female=e.target.value
    }
    if(update=='pwd'){
        current.pwd=e.target.value
    }
    setpolicymakers(current)

}
function updategStaff(e,update){
    let current={...gstaff}
    if(update=='male'){
        current.male=e.target.value
    }
    if(update=='female'){
        current.female=e.target.value
    }
    if(update=='pwd'){
        current.pwd=e.target.value
    }
    setgstaff(current)

}
function updateAwareness(e,update){
    let current={...ppawareness}
    if(update=='male'){
        current.male=e.target.value
    }
    if(update=='female'){
        current.female=e.target.value
    }
    if(update=='pwd'){
        current.pwd=e.target.value
    }
    setppawarenesss(current)

}
function updateCapacity(e,update){
    let current={...smcapacity}
    if(update=='male'){
        current.male=e.target.value
    }
    if(update=='female'){
        current.female=e.target.value
    }
    if(update=='pwd'){
        current.pwd=e.target.value
    }
    setsmcapacity(current)

}
function updateTraining(e,update){
    let current={...bustrained}
    if(update=='male'){
        current.male=e.target.value
    }
    if(update=='female'){
        current.female=e.target.value
    }
    if(update=='pwd'){
        current.pwd=e.target.value
    }
    setbustrained(current)

}
function updateReview(e,update){
    let current={...rmeeting}
    if(update=='artisan'){
        current.artisans=e.target.value
    }
    if(update=='marketer'){
        current.marketers=e.target.value
    }
    if(update=='government'){
        current.government=e.target.value
    }
    setrmeeting(current)

}
function submitData(){
    if(month &&meetagenda && policymakers.male && policymakers.female && policymakers.pwd && gstaff.male && gstaff.female && gstaff.pwd && visitedHH && pwd && awareness && ppawareness.male && ppawareness.female && ppawareness.pwd && smcapacity.male && smcapacity.female && smcapacity.pwd && bustrained.male && bustrained.female && bustrained.pwd && rmeeting.artisans && rmeeting.marketers && rmeeting.government){
        const body={month:month,
        meetagenda:meetagenda,
        policymakers:policymakers,
        gstaff:gstaff,
        hhvisited:hhvisited,
        awareness:awareness,
        ppawareness:ppawareness,
        smcapacity:smcapacity,
        bustrained:bustrained,
        rmeeting:rmeeting,
        householdsVisited:visitedHH,
        pwdHH:pwd}

        fetch('/api/v1/populateexcel',{method:'POST', credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(body)}).then(res=>res.json()).then(res=>{
            if(res.status==200){
                setpolicymakers({male:null,female:null,pwd:null})
                setgstaff({male:null,female:null,pwd:null})
                sethhvisited(null)
                setawareness(null)
                setppawarenesss({male:null,female:null,pwd:null})
                setsmcapacity({male:null,female:null,pwd:null})
                setbustrained({male:null,female:null,pwd:null})
                setrmeeting({artisans:null,marketers:null,government:null})
                window.location.reload();
            }
        })
    }else{
        alert('some data is missing')
    }
}
    return( 
        <div>
            <Navbar user={user}/>
            {/* <div className='first-element'>
			</div> */}
            <div className='feed-data-container'>
                <div className='feed-data-container-header'>
                    <div className='feed-data-container-header-col1'>
                    <div className='feed-data-container-header-row1'>
                        <h3>KPIs Data Entry</h3>
                    </div>
                    <div className='feed-data-container-header-row2'>
                        <h5>Use this page to key in monthly data on government, communities,entrepreneurs and FI engagements</h5>
                    </div>
                    </div>
                    <div className='feed-data-container-header-col2'>
                    <div className='feed-data-container-header-month'>
                    <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="monthfilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Reporting Month</InputLabel>
                    <Select
                      labelId="monthfilter"
                      id="monthfilterid"
                      value={month}
                      label="Reporting Month"
                      onChange={e=>setmonth(e.target.value)}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
                    {months.map((c,i)=>(
                      <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
                    ))}
                    </Select>
          </FormControl>
                    </div>
          {month &&meetagenda && policymakers.male && policymakers.female && policymakers.pwd && gstaff.male && gstaff.female && gstaff.pwd && visitedHH && pwd && awareness && ppawareness.male && ppawareness.female && ppawareness.pwd && smcapacity.male && smcapacity.female && smcapacity.pwd && bustrained.male && bustrained.female && bustrained.pwd && rmeeting.artisans && rmeeting.marketers && rmeeting.government && 
                    <div className='feed-data-container-header-save' onClick={e=>submitData()}>
                        <p>Save and Publish</p>
                    </div>
}
                    </div>
                </div>
                {active && 
                <div className='feed-data-container-body'>
                        <h4 className='kpi-title'>Government engagement</h4>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of meetings (with agenda/no handshaking) with policy makers - Disaggregation by level policy maker (local, national, international)</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Number of meetings"
                            type="number"
                            size='small'
                            value={meetagenda}
                            onChange={e=>{setmeetagenda(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of policy makers engaged - Disaggregation by level policy maker (local, national, international) and gender policy maker</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Males"
                            type="number"
                            size='small'
                            value={policymakers.male}
                            onChange={e=>{updatePolicyMakers(e,'male')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number1"
                            label="Females"
                            type="number"
                            size='small'
                            value={policymakers.female}
                            onChange={e=>{updatePolicyMakers(e,'female')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number2"
                            label="PWDs"
                            type="number"
                            size='small'
                            value={policymakers.pwd}
                            onChange={e=>{updatePolicyMakers(e,'pwd')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of county government staff engaged/sensitized/capacity built</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Males"
                            type="number"
                            size='small'
                            value={gstaff.male}
                            onChange={e=>{updategStaff(e,'male')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Females"
                            type="number"
                            size='small'
                            value={gstaff.female}
                            onChange={e=>{updategStaff(e,'female')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="PWDs"
                            type="number"
                            size='small'
                            value={gstaff.pwd}
                            onChange={e=>{updategStaff(e,'pwd')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>

                    </div>
                        <h4 className='kpi-title'>Community engagement</h4>
                        {/* <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of households visited</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Number of households"
                            type="number"
                            size='small'
                            value={hhvisited}
                            onChange={e=>{sethhvisited(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        <div className='single-number'>
                        <FormControlLabel control={<Switch defaultChecked />} label="Auto calculate" />
                        </div>
                        </div>
                    </div> */}
                        <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of direct awareness raising activities (always paid for by FINISH)</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Number of activities"
                            type="number"
                            size='small'
                            value={awareness}
                            onChange={e=>{setawareness(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of people present during these direct awareness raising activities (sum) - Disaggregation by gender</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Males"
                            type="number"
                            size='small'
                            value={ppawareness.male}
                            onChange={e=>{updateAwareness(e,'male')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Females"
                            type="number"
                            size='small'
                            value={ppawareness.female}
                            onChange={e=>{updateAwareness(e,'female')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="PWDs"
                            type="number"
                            size='small'
                            value={ppawareness.pwd}
                            onChange={e=>{updateAwareness(e,'pwd')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of sanitation marketeers capacity built</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Males"
                            type="number"
                            size='small'
                            value={smcapacity.male}
                            onChange={e=>{updateCapacity(e,'male')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Females"
                            type="number"
                            size='small'
                            value={smcapacity.female}
                            onChange={e=>{updateCapacity(e,'female')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="PWDs"
                            type="number"
                            size='small'
                            value={smcapacity.pwd}
                            onChange={e=>{updateCapacity(e,'pwd')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <h4 className='kpi-title'>Entrepreneurs engagement</h4>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of entrepreneurs trained (business/coaching)</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Males"
                            type="number"
                            size='small'
                            value={bustrained.male}
                            onChange={e=>{updateTraining(e,'male')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Females"
                            type="number"
                            size='small'
                            value={bustrained.female}
                            onChange={e=>{updateTraining(e,'female')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="PWDs"
                            type="number"
                            size='small'
                            value={bustrained.pwd}
                            onChange={e=>{updateTraining(e,'pwd')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <h4 className='kpi-title'>Review meetings</h4>
                    <div className='kpi'>
                        <div className='kpi-description'>
                            <p>Number of people present during review meetings</p>
                        </div>
                        <div className='kpi-data-inputs'>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Artisans"
                            type="number"
                            size='small'
                            value={rmeeting.artisans}
                            onChange={e=>{updateReview(e,'artisan')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Marketers"
                            type="number"
                            size='small'
                            value={rmeeting.marketers}
                            onChange={e=>{updateReview(e,'marketer')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                            <div className='single-number'>
                                <Box 
                            sx={{fontFamily:'Cera Pro2'}}>

                            <TextField
                            id="outlined-number"
                            label="Government Staff"
                            type="number"
                            size='small'
                            value={rmeeting.government}
                            onChange={e=>{updateReview(e,'government')}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                
                }

            </div>
        </div>
    )
}
export default FeedData