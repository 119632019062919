import { AiOutlineClose } from "react-icons/ai";
import System from "./SideMenuChildren/System";
import React, { useState, useEffect } from "react";
import Results from "./SideMenuChildren/Results";
import { Systems } from "../../systems";
import Filter from "./SideMenuChildren/Filter";
import SystemPreview from "./SideMenuChildren/SystemPreview";
import loadingImage from "../../images/5.png";
import filterImage from "../../images/filter.png";
import notfoundImage from "../../images/notfound.png";
import mapimage from "../../images/loader.gif";
import ReactMapGL, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { AiOutlineRight } from "react-icons/ai";
import noimage from "../../images/noimage.webp";
import { MdOutlineCleanHands } from "react-icons/md";
import { BsCheckLg } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import { FaSoap } from "react-icons/fa";
import { IoWaterSharp } from "react-icons/io5";
import { GiPathDistance } from "react-icons/gi";

const SideMenu = ({
	close,
	page,
	sysclicked,
	marketer,
	systemgps,
	thesystem,
}) => {
	const [mysystems, setsystems] = useState([]);
	const [query, setquery] = useState("");
	const [isPending, setisPending] = useState(false);
	const [Preview, setPreview] = useState(false);
	const [ismap, setismap] = useState(null);
	const [coord, setcoord] = useState(null);
	const [isfiltered, setisFiltered] = useState(false);
	const [systemsPreview, setsystemsPreview] = useState(true);
	const [systempreviewid, setsystempreviewid] = useState(null);
	const [isSan, setisSan] = useState(null);
	const [showcontainer, setshowcontainer] = useState(false);
	const [sansystem, setsansystem] = useState(null);
	const [showresults, setshowresults] = useState(false);
	const [showabsent, setshowabsent] = useState(false);
	const [filters, setfilters] = useState({
		year: null,
		month: null,
		toilet: null,
	});
	const [viewport, setViewport] = useState({
		latitude: 1,
		longitude: 37,
		zoom: 7,
		width: "100%",
		height: "70%",
	});
	let style = "side-menu active";
	let approval;
	let system;
	let sanimagelink =
		"https://kc.datacollection.amref.org/media/original?media_file=";
	switch (page) {
		case "Not Verified":
			approval = "Not Verified";
			system = "notapproved";
			break;
		case "Pending":
			approval = "Pending";
			system = "pending";
			break;
		case "Action Needed":
			approval = "Action Needed";
			system = "actionsystems";
			break;
		case "Approved":
			approval = "Approved";
			system = "approvedsystems";
			break;
		case "Rejected":
			approval = "Rejected";
			system = "rejectedsystems";
			break;
		case "map":

		default:
			break;
	}
	function handleImagePreview(id) {
		setsystempreviewid(id);
		setPreview(true);
		setsystemsPreview(false);
	}
	function handleMarketerPerfomance() {
		// window.open('http:localhost:3001/signin','_self')
		setPreview(true);
		setsystempreviewid(null);
		setsystemsPreview(false);
	}
	function handleImagePreviewBack() {
		setPreview(false);
		setsystemsPreview(true);
	}
	function filter(year, month, toilet) {
		setisFiltered(true);
		setfilters({ year: year, month: month, toilet: toilet });
	}
	function fetchData() {
		if (isfiltered) {
			let body = {
				year: filters.year,
				month: filters.month,
				toilet: filters.toilet,
			};
			if (page && page != "map" && page != "sanitation") {
				setsystems([]);
				setshowcontainer(true);
				setisPending(true);
				setshowresults(false);
				setshowabsent(false);
				fetch("/api/v1/" + system, {
					credentials: "include",
					method: "POST",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((data) => data.json())
					.then((data) => {
						setisPending(false);
						setisFiltered(false);
						setshowresults(true);
						if (data.toilets.length < 1) {
							setshowabsent(true);
						}
						setsystems(data.toilets);
					});
			}
			if (marketer) {
				setsystems([]);
				setisPending(true);
				setshowcontainer(true);
				setshowresults(false);
				setshowabsent(false);
				fetch("/api/v1/marketer/" + marketer, {
					credentials: "include",
					method: "POST",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((data) => data.json())
					.then((data) => {
						setisPending(false);
						setisFiltered(false);
						setshowresults(true);

						if (data.toilets.length < 1) {
							setshowabsent(true);
						}
						setsystems(data.toilets);
					});
			}
		}
	}
	useEffect(() => {
		if (page == "map") {
			setsystemsPreview(false);
			fetch("/api/v1/onesystem/" + systemgps, {
				method: "get",
				credentials: "include",
			})
				.then((data) => {
					return data.json();
				})
				.then((data) => {
					setismap(true);
					setViewport({
						latitude: data.toilet[0].lat,
						longitude: data.toilet[0].long,
						zoom: 16,
						width: "100%",
						height: "70%",
					});
					return setcoord([data.toilet[0].lat, data.toilet[0].long]);
				});
		}
		if (page == "sanitation") {
			setsystemsPreview(false);
			fetch("/api/v1/onesystem/" + thesystem, {
				method: "get",
				credentials: "include",
			})
				.then((data) => {
					return data.json();
				})
				.then((data) => {
					setisPending(false);
					setisSan(true);
					setsansystem(data.toilet[0]);
				});
		}
	}, []);
	useEffect(() => {
		fetchData();
	}, [isfiltered]);
	useEffect(() => {
		return;
	}, [Preview]);
	useEffect(() => {
		return;
	}, [systemsPreview]);
	useEffect(() => {
		return;
	}, [systempreviewid]);
	return (
		<div className={style}>
			{!showcontainer && systemsPreview && (
				<div className='filter-container'>
					<div className='close-button' onClick={() => close()}>
						<div className='the-close'>
							<h2>
								<AiOutlineClose />
							</h2>
						</div>
						{page && (
							<div className='side-menu-heading'>
								<h3>{page} Toilets.</h3>
							</div>
						)}
						{marketer && (
							<div className='side-menu-heading'>
								<h3>{page} Marketer's Toilets.</h3>
							</div>
						)}
					</div>
					<Filter filter={filter} />
					{!isPending && <img src={filterImage} className='filterImage'></img>}
					{/* {isPending && <div className='loading-image'>
					{ page != 'map' && <img src={mapimage} alt="" />}
					</div>} */}
				</div>
			)}
			{showcontainer && systemsPreview && (
				<div className='side-menu-items'>
					<div className='close-button' onClick={() => close()}>
						<div className='the-close'>
							<h2>
								<AiOutlineClose />
							</h2>
						</div>
						{page && (
							<div className='side-menu-heading'>
								<h3>{page} Toilets.</h3>
							</div>
						)}
						{marketer && (
							<div className='side-menu-heading'>
								<h3>{page} Marketer's Toilets.</h3>
							</div>
						)}
					</div>
					<input
						type='text'
						className='search-bar-inp'
						placeholder=" Search For  A Marketer's Toilets"
						onChange={(e) => setquery(e.target.value)}
					/>
					<Filter filter={filter} />
					{/* {isPending && <div className='loading-image'>
					{ page != 'map' && <img src={mapimage} alt="" />}
					</div>} */}
					{/* {marketer && 
						<h4 className='marketer-perfomance' onClick={e=>handleMarketerPerfomance()}>View Marketer's Perfomance <FcBullish/> </h4>
					} */}
					{mysystems.length < 1 && isPending && (
						<Results
							systems={[]}
							approval={approval}
							query={query}
							sysclicked={sysclicked}
							imagePreview={handleImagePreview}
							marketer={marketer}
						/>
					)}
					{mysystems.length > 0 && showresults && (
						<Results
							systems={mysystems}
							approval={approval}
							query={query}
							sysclicked={sysclicked}
							imagePreview={handleImagePreview}
							marketer={marketer}
						/>
					)}
					{showabsent && (
						<img src={notfoundImage} className='filterImage'></img>
					)}
				</div>
			)}
			{isPending && (
				<div className='loading-image'>
					{page == "map" && <img src={loadingImage} alt='' />}
				</div>
			)}
			{ismap && (
				<div className='map-div'>
					{/* <Navbar isAuth={isAuth} user={user}/> */}
					<div className='close-button-div' onClick={() => close()}>
						<h2>
							<AiOutlineRight />
						</h2>
					</div>
					<ReactMapGL
						{...viewport}
						mapboxAccessToken={
							"pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw"
						}
						mapStyle='mapbox://styles/mapbox/satellite-streets-v12'
						onLoad={(viewport) => {
							setViewport(viewport);
						}}>
						{coord && (
							<Marker latitude={coord[0]} longitude={coord[1]}></Marker>
						)}
					</ReactMapGL>
				</div>
			)}
			{isSan && (
				<div className='system-hygiene'>
					<div className='close-button-div' onClick={() => close()}>
						<h2>
							<AiOutlineClose />
						</h2>
					</div>
					<div className='system-hygiene-container'>
						<div>
							<h2>Toilet Hygiene</h2>
						</div>
						<div className='system-hygiene-image'>
							{sansystem && sansystem.hygieneImage && (
								<img
									className='hygiene-image'
									src={sanimagelink + sansystem.hygieneImage}
								/>
							)}
							{sansystem && !sansystem.hygieneImage && (
								<img className='hygiene-image' src={noimage} />
							)}
						</div>
						<div className='toilet-hygiene-icons'>
							<div className='skip-container'>
								{sansystem &&
									sansystem.hygieneType !=
										"No handwashing place available in dwelling/plot/yard" && (
										<div className='san-indicator'>
											<h1>
												<BsCheckLg className='san-indicator-icon' />
											</h1>
										</div>
									)}
								{sansystem &&
									sansystem.hygieneType ==
										"No handwashing place available in dwelling/plot/yard" && (
										<div className='san-indicator-abs'>
											<h1>
												<BiError className='san-indicator-icon' />
											</h1>
										</div>
									)}
								<h1 className='skip-button'>
									<MdOutlineCleanHands />
								</h1>
							</div>
							<div className='skip-container'>
								{/* {sansystem && sansystem.hygieneWater && sansystem.hygieneWater=="Water Available" && <div className="san-indicator">
                      <h1><BsCheckLg className="san-indicator-icon"/></h1>
                 </div>}
				 { sansystem && sansystem.hygieneType=="No handwashing place available in dwelling/plot/yard" || (sansystem.hygieneWater && sansystem.hygieneWater=='Water Unavailable')&& <div className="san-indicator-abs">
                      <h1><BiError className="san-indicator-icon"/></h1>
                 </div>} */}
								<h1 className='skip-button'>
									<IoWaterSharp />
								</h1>
							</div>
							<div className='skip-container'>
								<div className='san-indicator'>
									<h1>
										<BsCheckLg className='san-indicator-icon' />
									</h1>
								</div>
								<h1 className='skip-button'>
									<FaSoap />
								</h1>
							</div>
							<div className='skip-container'>
								<div className='san-indicator'>
									<h1>
										<BsCheckLg className='san-indicator-icon' />
									</h1>
								</div>
								<h1 className='skip-button'>
									<GiPathDistance />
								</h1>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default SideMenu;
