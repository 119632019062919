import React, { useState, useEffect } from 'react';
import { FcNext } from "react-icons/fc";
import Button from '@mui/material/Button';
const Approval = ({ approval, system,pend,reject,updatesystem,waiting,change,next,prev,user}) => {
useEffect(() => {
  return () => {
  }
}, [system])


	return (
		<>
		{!waiting && <div className='approval-buttons'>
			{	system.approval !="Action Needed" && system.approval != 'Approved' && system.approval != 'Rejected' &&
				<div>
					<Button
					className='prev-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => prev(system._id,system.approval)}>
	
					Previous
				</Button>
					<Button
					color="success"
					sx={{fontFamily:'Cera Pro'}}
					className='approve-button'
					onClick={() => {approval(system._id, 'Approved')
									change()}}>
					Approve
				</Button>
				{system.approval != 'Pending' && system.country!="Uganda" && (
					<Button
						color='warning'
						sx={{fontFamily:'Cera Pro'}}
						className='pend-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => pend(system._id)}>
						Pend
					</Button>
				)}
				<Button
						color='error'
						sx={{fontFamily:'Cera Pro'}}
						className='reject-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => reject(system._id)}>
						Reject
					</Button>
				<Button
					className='next-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => next(system._id,system.approval)}>
	
				Next
				</Button>
				
            

				</div>}
			{ system.approval=="Action Needed" && 
			<div>
			{/* <button className="update-button" onClick={e=>updatesystem()}>
					Update Toilet Details
			</button> */}
			<Button
					className='prev-button'
					
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => prev(system._id,system.approval)}>
	
					Previous
				</Button>
				<Button
						
						color='error'
						sx={{fontFamily:'Cera Pro'}}
						className='reject-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => reject(system._id)}>
						Reject
					</Button>
					<Button
				
					className='next-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => next(system._id,system.approval)}>
	
				Next
				</Button>
			</div>}
			{ system.approval=="Approved" && 
			<div>
				<Button
					className='prev-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => prev(system._id,system.approval)}>
	Previous
				</Button>
			{user.isAuth &&
			<>
			<Button
						color='error'
						sx={{fontFamily:'Cera Pro'}}
						className='reject-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => reject(system._id)}>
						Revoke Approved and Reject
					</Button>
			<Button
						color='warning'
						sx={{fontFamily:'Cera Pro'}}
						className='pend-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => pend(system._id)}>
						Revoke Approved and Pend
					</Button>
			</>

			}
				<Button
					className='next-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => next(system._id,system.approval)}>Next
	
				</Button>

			</div>}
		</div>}
		{waiting && <div className='approval-buttons'>
			{	system.approval !="Action Needed" && system.approval != 'Approved' && system.approval != 'Rejected' &&
				
				<div>
					<Button
					className='prev-button'
					disabled
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => prev(system._id,system.approval)}>
	
					Previous
				</Button>
					<Button
					disabled
					color="success"
					sx={{fontFamily:'Cera Pro'}}
					className='approve-button'
					onClick={() => {approval(system._id, 'Approved')
									change()}}>
					Approve
				</Button>
				{system.approval != 'Pending' && system.country!="Uganda" && (
					<Button
						color='warning'
						disabled
						sx={{fontFamily:'Cera Pro'}}
						className='pend-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => pend(system._id)}>
						Pend
					</Button>
				)}
				<Button
						color='error'
						disabled
						sx={{fontFamily:'Cera Pro'}}
						className='reject-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => reject(system._id)}>
						Reject
					</Button>
				<Button
				disabled
					className='next-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => next(system._id,system.approval)}>
	
				Next
				</Button>
				
            

				</div>}
			{ system.approval=="Action Needed" && 
			<div>
			{/* <button className="update-button" onClick={e=>updatesystem()}>
					Update Toilet Details
			</button> */}
			<Button
					className='prev-button'
					disabled
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => prev(system._id,system.approval)}>
	
					Previous
				</Button>
				<Button
						disabled
						color='error'
						sx={{fontFamily:'Cera Pro'}}
						className='reject-button'
						// onClick={() => approval(system._id, 'Pending')}>
						onClick={() => reject(system._id)}>
						Reject
					</Button>
					<Button
				disabled
					className='next-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => next(system._id,system.approval)}>
	
				Next
				</Button>

			</div>}
			{ system.approval=="Approved" && 
			<div>
				<Button
					className='prev-button'
					disabled
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => prev(system._id,system.approval)}>
	
					Previous
				</Button>
				{/*  */}
				<Button
				disabled
					className='next-button'
					// onClick={() => approval(system._id, 'Rejected')}>
					onClick={() => next(system._id,system.approval)}>
						Next
				</Button>

			</div>}
		</div>}
		</>
	);
};

export default Approval;
