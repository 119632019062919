import VerificationBox from './Verification-Box/VerificationBox';
import VerificationImage from './Verification-Images/VerificationImage';
import SimilarImage from './SimilarImages/SimilarImages'
const VerificationCOntainer = ({ system, approve,marketer ,ttapprove,handleSkip,handleReject,handlePend,reloadsystems,user,handleReveal,handleMap,handleSan,handlePrev,handleNext}) => {
	return (
		<div className='verification-container'>
			<div className='v-container-left'> 
			<VerificationBox reloadsystems={reloadsystems} system={system} approve={approve} marketer={marketer} handleSkip={handleSkip} handleReject={handleReject} handlePend={handlePend} user={user} 
			handleReveal={handleReveal}	handleMap={handleMap} handleSan={handleSan} ttapprove={ttapprove} handlePrev={handlePrev} handleNext={handleNext}	
			/>
			{/* <SimilarImage system={system}/> */}
			</div>
			<VerificationImage system={system} user={user}/>
		</div>
	);
};

export default VerificationCOntainer;
