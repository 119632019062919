const System = ({
	name,
	systype,
	doors,
	sysclicked,
	sysid,
	version,
	approval,
	imagePreview,
	markCont,
	link1,
}) => {
	let imagelink;
	if (version == "v1") {
		imagelink =
			"https://kc.datacollection.amref.org/media/original?media_file=judy_murithi%2Fattachments/";
	} else if (version == "v2") {
		imagelink =
			"https://kc.datacollection.amref.org/media/original?media_file=";
	}
	return (
		<div className='single-system'>
			<div className='first-col' onClick={() => sysclicked(sysid, approval)}>
				<h4>
					Marketer: <strong>{name}</strong>
				</h4>
				<h4>Toilet Type: {systype}</h4>
				<h4>Number of Doors: {doors}</h4>
				<h4>Approval:{approval}</h4>
				<h4>Contact: 0{markCont}</h4>
			</div>
			<div className='second-col'>
				{/* <button className="preview-images" onClick={(e)=>{e.preventDefault()
                    imagePreview(sysid)}}>Preview Images</button> */}
				<img src={imagelink + link1}></img>
			</div>
		</div>
	);
};

export default System;
