import Indicator from "./Indicators/VerificationIndicator";
import VerificationContainer from "./VerificationContainers/VerificationContainer";
// import Navbar from "./Navbar";
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import NoSystem from "./NoSystem";

const Verification = ({
	nav,
	tempApproval,
	onesystem,
	handleReject,
	handlePend,
	sysstate,
	user,
	handleReveal,
	handleMap,
	handleSan,
	edited,
	editedfetched,
	isAuth
}) => {
	let mysysnum;
	let timetoaction = 0;
	let indicatorArray = [
		"Approved",
		"Rejected",
		"Pending",
		"Action Needed",
		"Not Verified",
	];
	const [sysnum, setsysnum] = useState(null);
	const [indicatorData, setIndicatorData] = useState({
		view: null,
		data: null,
		marketer: null,
	});
	const [systems, setsystems] = useState([]);
	const [firstSystem, setfirstSystem] = useState([]);
	async function approveSystem(id, approval) {
		let body = {
			time: timetoaction,
		};
		let approvalroute;
		switch (approval) {
			case "Approved":
				approvalroute = "approve";
				break;
			case "Pending":
				approvalroute = "pend";
				break;
			case "Rejected":
				approvalroute = "reject";
				break;
			default:
				break;
		}
		localStorage.setItem("Images", JSON.stringify([]));
		return fetch("/api/v1/" + approvalroute + "/" + id, {
			method: "post",
			credentials: "include",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(body),
		})
			.then((data) => data.json())
			.then((data) => {
				return;
				// setsystems(data.systems);
			});
	}
	setInterval(() => {
		timetoaction = timetoaction + 1;
	}, 1000);

	function getFirstSystem() {
		let approval;
		switch (tempApproval) {
			case "Not Verified":
				approval = "notverified";
				break;
			case "Pending":
				approval = "pending";
				break;
			case "Action Needed":
				approval = "actionsystems";
				break;
			default:
				break;
		}
		if(!isAuth) {
			if (firstSystem.length > 0) {
				let thesys = [...firstSystem];
				thesys[0].link1 = null;
				setfirstSystem(thesys);
			}
			localStorage.setItem("Images", JSON.stringify([]));

			fetch("/api/v1/firstsystem/approved" , { credentials: "include" })
				.then((data) => data.json())
				.then((data) => {
					setfirstSystem(data.toilets);
					editedfetched(true);
				});
		}
		else if (isAuth && user.user.country == "Uganda") {
			if (firstSystem.length > 0) {
				let thesys = [...firstSystem];
				thesys[0].link1 = null;
				setfirstSystem(thesys);
			}
			localStorage.setItem("Images", JSON.stringify([]));
			fetch("/api/v1/firstsystem/uganda/" + approval, {
				credentials: "include",
			})
				.then((data) => data.json())
				.then((data) => {
					setfirstSystem(data.toilets);
					editedfetched(true);
				});
		} else if(isAuth && user.user.country == "Kenya") {
			if (firstSystem.length > 0) {
				let thesys = [...firstSystem];
				thesys[0].link1 = null;
				setfirstSystem(thesys);
			}
			localStorage.setItem("Images", JSON.stringify([]));

			fetch("/api/v1/firstsystem/" + approval, { credentials: "include" })
				.then((data) => data.json())
				.then((data) => {
					setfirstSystem(data.toilets);
					editedfetched(true);
				});
		}
		
	}
	function getAllSystems() {
		fetch("/api/v1/systems", { credentials: "include" })
			.then((data) => data.json())
			.then((data) => {
				setsystems(data.toilets);
			});
	}
	async function handlePrev(id,approval2) {
		let approval;
		switch (approval2) {
			case "Not Verified":
				approval = "notverified";
				break;
			case "Pending":
				approval = "pending";
				break;
			case "Action Needed":
				approval = "actionsystems";
				break;
				case "Approved":
					approval = "approved";
					break;
			case "Rejected":
				approval = "rejected";
				break;
				
			default:
				break;
		}
		if (firstSystem.length > 0) {
			let thesys = [...firstSystem];
			thesys[0].link1 = null;
			setfirstSystem(thesys);
		}
		localStorage.setItem("Images", JSON.stringify([]));
		return fetch("/api/v1/prevsystem/"+approval+"/"+id, {
			method: "get",
			credentials: "include",
		})
			.then((res) => res.json())
			.then((data) => {
				setfirstSystem(data.toilets);
				editedfetched(true);
			});
	}
	async function handleNext(id,approval2) {
		let approval;
		switch (approval2) {
			case "Not Verified":
				approval = "notverified";
				break;
			case "Pending":
				approval = "pending";
				break;
			case "Action Needed":
				approval = "actionsystems";
				break;
				case "Approved":
					approval = "approved";
					break;
			case "Rejected":
				approval = "rejected";
				break;
			default:
				break;
		}
		if (firstSystem.length > 0) {
			let thesys = [...firstSystem];
			thesys[0].link1 = null;
			setfirstSystem(thesys);
		}
		localStorage.setItem("Images", JSON.stringify([]));
		return fetch("/api/v1/nextsystem/"+approval+"/"+id, {
			method: "get",
			credentials: "include",
		})
			.then((res) => res.json())
			.then((data) => {
				setfirstSystem(data.toilets);
				editedfetched(true);
			});
	}
	// function handleSkip(id) {
	// 	skipSystem(id).then((result) => {
	// 		getFirstSystem();
	// 	});
	// }

	// let verificationsystems = [
	// 	...systems
	// 		.filter((system) => {
	// 			return system.approval == tempApproval;
	// 		})
	// 		.sort((a, b) => Number(a.skip) - Number(b.skip)),
	// ];

	const handleIndicatorClick = (IndicatorData) => {
		setIndicatorData({ data: IndicatorData });
	};
	const handleApprove = (id, approval) => {
		approveSystem(id, approval).then((smth) => {
			getFirstSystem();
			getAllSystems();
		});
	};

	function handleMarketerClick(marketer) {
		setIndicatorData({ marketer: marketer });
	}
	function reloadSystems() {
		getFirstSystem();
		getAllSystems();
	}
	const didUpdate = useRef(false);
	useEffect(() => {
			if (didUpdate.current) {
				return nav(true, indicatorData.data, indicatorData.marketer);
			} else {
				didUpdate.current = true;
			}
		
	}, [indicatorData]);
	useEffect(() => {
		mysysnum = {
			// approved: systems.filter((sys) => {
			// 	return sys.approval == 'Approved';
			// }).length,
			// rejected: systems.filter((sys) => {
			// 	return sys.approval == 'Rejected';
			// }).length,
			pending: systems.filter((sys) => {
				return sys.approval == "Pending";
			}).length,
			notverified: systems.filter((sys) => {
				return sys.approval == "Not Verified";
			}).length,
			action: systems.filter((sys) => {
				return sys.approval == "Action Needed";
			}).length,
		};
		setsysnum(mysysnum);
	}, [systems]);
	useEffect(() => {
		if(isAuth){
			getAllSystems();
		}
	}, []);
	useEffect(() => {
		getFirstSystem();
	}, []);
	useEffect(() => {
		if(isAuth){
			getFirstSystem();
		}
	}, [edited]);

	useEffect(() => {
		//   countTime()
	}, [firstSystem]);

	useEffect(() => {
		if(isAuth){
			getFirstSystem();
			getAllSystems();
		}
	}, [sysstate]);
	useEffect(() => {
		if (onesystem) {
			setfirstSystem(onesystem);
		}
	}, [onesystem]);
	return (
		<div>
			<Navbar user={user} />
			<div className='container '>
				{sysnum && (
					<div className='ver-numbers first-element'>
						{user.isAuth &&
						<>
						<div className='current-verification-page'>
							<Indicator
								ver={tempApproval}
								indicatorclick={handleIndicatorClick}
								systems={sysnum}
								user={user}
							/>
						</div>
						<div className='other-verification-pages'>
							{indicatorArray
								.filter((arr) => arr != tempApproval)
								.map((arr) => (
									<Indicator
										ver={arr}
										indicatorclick={handleIndicatorClick}
										systems={sysnum}
										key={arr}
								user={user}

									/>
								))}
						</div>
						</>
						}
						{!user.isAuth && 
						<div className='current-verification-page'>
						<Indicator
							ver={'Approved'}
							indicatorclick={handleIndicatorClick}
							systems={sysnum}
							user={user}
						/>
					</div>
						}
					</div>
				)}

				{firstSystem.length > 0 && (
					<VerificationContainer
						system={firstSystem[0]}
						approve={handleApprove}
						marketer={handleMarketerClick}
						handlePend={handlePend}
						handleReject={handleReject}
						reloadsystems={reloadSystems}
						user={user}
						handleReveal={handleReveal}
						handleMap={handleMap}
						handleSan={handleSan}
						ttapprove={timetoaction}
						handleNext={handleNext}
						handlePrev={handlePrev}
					/>
				)}
				{firstSystem.length < 1 && <NoSystem />}
			</div>
		</div>
	);
};

export default Verification;
