import "./index.css";

import Navbar from "../containers/Navbar/Navbar";
import laptop from "../images/laptop.png";
import React, { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import signin from "../images/microsoft.png";
import { TiVendorMicrosoft } from "react-icons/ti";

const Homepage = ({ user }) => {
	// const user=useContext(UserContext)
	function login() {
		window.location.href = "/signin";
	}
	return (
		<div>
			<Navbar user={user} />
			{/* <div className='image-background'>
            <h1>Sanitation Interventions Outcome Monitoring</h1>
            <div className="get-started-button">
                    <button onClick={()=>login()}>Get started</button>
                </div>
        </div> */}
			<div className='home-body'>
				{/* <div className='header'>
                    <h1>Hello👋 , Welcome to FmVisualize (v2.0)</h1> */}
				{/* <p>Welcome to Real-Time Sanitation Outcome Monitoring</p> */}
				{/* </div> */}
				<div className='heading'>
					<h1>
					 Real-Time Sanitation Outcome Monitoring.
					</h1>
					<h4>Fmvisualize provides a pipeline for reporting, cleaning, verification and visualization of sanitation data for the FINISH Mondial Program in Kenya and Uganda.</h4>
				{!user.isAuth && 
				<>
				<p className="accountsetup">I already have an account with fmvisualize?</p>
				<div className='signin'  onClick={() => login()}>
				<h3><TiVendorMicrosoft className="miscrosoft" /> Continue with Microsoft</h3>
					</div>
				<hr style={{"width":"100%","color":"#fff", "border":"0.5px solid"}}></hr>
				<Link to='/Analysis'>
				<div className='signin'>
				<h3>Continue as Guest</h3>
					</div>
				</Link>
				</>
					
					}
				</div>
				{/* <div className='graph-div'> */}

				{/* <img src={laptop} />
                    
                    <div className='graph-details'>
                        <h1>Analyze and Visualize</h1>
                        <p>With FmVisualize v2.0, the user now experiences the flexibility of visualizing different data phenomenon with graphs and charts by making data queries to the FINISH Mondial database of sanitation facilities. Specify the data fields you are interested in and have charts populate with this information instantly.</p>
                    </div> */}
				{/* </div> */}
			</div>
		</div>
	);
};

export default Homepage;
