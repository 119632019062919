import React, { useState, useEffect,useContext } from 'react'
import { filtersContext } from "./Analyses";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UserContext } from '../../App';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
const FiltersPane=()=>{
const {user,setuser}=useContext(UserContext)
const {filters,setfilters}=useContext(filtersContext)
const [county,setcounty]=useState([])
const country=['Kenya','Uganda']
const counties=['Kisumu','Nakuru','Kitui','Embu','Meru','Tharaka Nithi','Homa Bay']
const ugcounties=['Bunyagabu','Kaborale','Ntoroko','Kamwenge','Kyenjonjo']
const months=[{num:0,month:'All Months'},{num:1,month:'January'},{num:2,month:'February'},{num:3,month:'March'},{num:4,month:'April'},{num:5,month:'May'},{num:6,month:'June'},{num:7,month:'July'},{num:8,month:'August'},{num:9,month:'September'},{num:10,month:'October'},{num:11,month:'November'},{num:12,month:'December'}]
const years=['2022','2023','2024']
const approvals=['Approved','Rejected','Pending','Action Needed','Not Verified']
const toiletstate=['All Toilets','Newly Constructed','Rehabilitated']

const MenuProps = {
  PaperProps: {
    style: {
        fontSize:'12px', fontFamily:'Cera Pro2'
    },
  },
};
function countyFiltered(e){
    const {
        target: { value },
      } = e;
      setcounty(
        typeof value === 'string' ? value.split(',') : value
      );
    
}
function yearFiltered(e){
	let newfilters={...filters}
    newfilters.year=e.target.value
	setfilters(newfilters)
}
function monthFiltered(e){
	let newfilters={...filters}
    let month=e.target.value
    newfilters.month=month
	setfilters(newfilters)
}
function approvalFiltered(e){
	let newfilters={...filters}
    newfilters.approval=e.target.value
	setfilters(newfilters)
}
function toiletFiltered(e){
	let newfilters={...filters}
    newfilters.state=e.target.value
	setfilters(newfilters)
}
function countryFiltered(e){
	let newfilters={...filters}
    newfilters.country=e.target.value
	setfilters(newfilters)
}
useEffect(()=>{
},[filters])
useEffect(()=>{
         let newfilters={...filters}
         newfilters.county=county
	    setfilters(newfilters)
},[county])
    return (
        <>
        {filters && 
            <div className="filterspane">
          <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Country</InputLabel>
                    <Select
                      labelId="statefilter"
                      id="countryfilterid"
                      value={filters.country}
                      label="Filter Country"
                      onChange={countryFiltered}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
                    {country.map((c,i)=>(
                      <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
                    ))}
                    </Select>
          </FormControl>
        <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Type</InputLabel>
                    <Select
                      labelId="statefilter"
                      id="yearfilterid"
                      value={filters.state}
                      label="Filter by year"
                      onChange={toiletFiltered}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
                    {toiletstate.map((c,i)=>(
                      <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
                    ))}
                    </Select>
          </FormControl>
        <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="yearfilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Year</InputLabel>
                    <Select
                      labelId="yearfilter"
                      id="yearfilterid"
                      value={filters.year}
                      label="Filter by year"
                      onChange={yearFiltered}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
                    {years.map((c,i)=>(
                      <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
                    ))}
                    </Select>
          </FormControl>
          <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="countyfilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Filter by County</InputLabel>
                    <Select
                      labelId="countyfilter"
                      id="countyfilterid"
                      multiple
                      value={county}
                      label="Filter by County"
                      onChange={countyFiltered}
                      input={<OutlinedInput label="Filter by County" />}
                      renderValue={(selected) => selected.join(', ')}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}
                      MenuProps={MenuProps}
                      >
                    {filters.country=='Kenya' && counties.map((c,i)=>(
                      <MenuItem value={c} key={i} >
                          <Checkbox checked={county.indexOf(c) > -1} ></Checkbox>
                            <ListItemText primary={c} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}></ListItemText>
                      </MenuItem>
                    ))}
                    {filters.country=='Uganda' && ugcounties.map((c,i)=>(
                      <MenuItem value={c} key={i} >
                          <Checkbox checked={county.indexOf(c) > -1} ></Checkbox>
                            <ListItemText primary={c} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}></ListItemText>
                      </MenuItem>
                    ))}
                    </Select>
          </FormControl>
          <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="monthfilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Filter by Month</InputLabel>
                    <Select
                      labelId="monthfilter"
                      id="monthfilterid"
                      value={filters.month}
                      label="Filter by month"
                      onChange={monthFiltered}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
                    {months.map((c,i)=>(
                      <MenuItem value={c.month} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c.month}</MenuItem>
                    ))}
                    </Select>
          </FormControl>
          {user.isAuth && <FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="small" className="formctrl">
                <InputLabel id="approvalfilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Select Approval</InputLabel>
                    <Select
                      labelId="approvalfilter"
                      id="approvalfilterid"
                      value={filters.approval}
                      label="FSelect Approval"
                      onChange={approvalFiltered}
                      sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
                    {approvals.map((c,i)=>(
                      <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
                    ))}
                    </Select>
          </FormControl>}
        </div>}
        </>
        )
}
export default FiltersPane