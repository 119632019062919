// import './App.css';
import Navbar from "./containers/Navbar/Navbar";
import Verification from "./containers/Verification/Verification";
import SideMenu from "./containers/SideMenu/SideMenu";
import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./containers/Dashboard/Dashboard";
import Download from "./containers/Downloads/Downloads";
import Backdrop from "./containers/Backdrop";
import Homepage from "./Pages/Index";
import ProtectedRoute from "./ProtectedRoutes";
import UserSetup from "./Pages/UserSetup/UserSetup";
import Analyses from "./Pages/Analyses/Analyses";
import Meetings from "./Pages/Meetings/Meetings";
import RevealMenu from "./containers/RevealMenu/RevealMenu";
import FeedData from "./Pages/DataEntry/EnterData";
export const UserContext=createContext();
function App() {
	//State Declarations
	const [user, setuser] = useState({ isAuth: false, user: {} });
	const [onesystem, setonesystem] = useState(null);
	const [sidenav, setsidenav] = useState({
		status: false,
		page: null,
		marketer: null,
		systemgps: null,
		thesystem: null,
	});
	const [revealmenu, setrevealmenu] = useState({
		status: false,
		system: null,
		action: null,
	});
	const [tempApproval, setTempApproval] = useState("Not Verified");
	const [pend, setpend] = useState({ show: false, system: null });
	const [reject, setreject] = useState({ show: false, system: null });
	const [showBackdrop, setshowBackdrop] = useState(false);
	const [verId, setverId] = useState(null);
	const [edited, setedited] = useState(false);
	const [sysChanged, setsysChanged] = useState(false);
	function handleReject(systemid) {
		setverId(systemid);
		setreject({ show: true, system: systemid });
		setpend({ show: false, system: null });
		setshowBackdrop(true);
	}
	function changeSysState() {
		setsysChanged(!sysChanged);
	}
	function clearVerId() {
		setverId(null);
	}
	function handlePend(systemid) {
		setverId(systemid);
		setpend({ show: true, system: systemid });
		setreject({ show: false, system: null });
		setshowBackdrop(true);
	}
	
	function handleCloseModal() {
		setshowBackdrop(false);
	}
	function fetchUser() {
		fetch("/api/v1/getuser", { credentials: "include" })
			.then((data) => data.json())
			.then((user) => {
				if (Object.keys(user.user).length != 0) {
					setuser({ isAuth: true, user: { ...user.user } });
				} else {
					setuser({ isAuth: false, user: {} });
				}
			});
	}
	function handleEdits(edited) {
		if (edited) {
			setedited(false);
		} else {
			setedited(true);
		}
	}

	//Functions
	const changeNavState = (data, page, marketer) => {
		setpend({ show: false, system: null });
		setreject({ show: false, system: null });
		setshowBackdrop(true);
		setsidenav({ status: data, page: page, marketer: marketer });
	};
	const handleClose = () => {
		setshowBackdrop(false);
		setsidenav({ status: false, page: null });
	};
	const handleSystemClick = (id, approval) => {
		handleClose();
		localStorage.setItem("Images", JSON.stringify([]));
		fetch("/api/v1/onesystem/" + id)
			.then((data) => data.json())
			.then((data) => {
				setonesystem(data.toilet);
			});
		setTempApproval(approval);
	};
	const handleRevealCLick = (systemid, action) => {
		setpend({ show: false, system: null });
		setreject({ show: false, system: null });
		setshowBackdrop(true);
		return setrevealmenu({ status: true, system: systemid, action: action });
	};
	const handleRevealClose = () => {
		setshowBackdrop(false);
		setrevealmenu({ status: false, action: null });
	};

	const showMap = (system) => {
		setpend({ show: false, system: null });
		setreject({ show: false, system: null });
		setshowBackdrop(true);
		setsidenav({ status: true, page: "map", systemgps: system });
	};

	const showSanitation = (system) => {
		setpend({ show: false, system: null });
		setreject({ show: false, system: null });
		setshowBackdrop(true);
		setsidenav({ status: true, page: "sanitation", thesystem: system });
	};
	//My Hooks
	useEffect(() => {
		return;
	}, [sidenav]);

	useEffect(() => {
		return;
	}, [tempApproval]);
	useEffect(() => {
		return;
	}, [onesystem]);
	useEffect(() => {
		return;
	}, [pend]);
	useEffect(() => {
		return;
	}, [reject]);
	useEffect(() => {
		return;
	}, [revealmenu]);
	useEffect(() => {
		fetchUser();
	}, []);
	return (
		<div>
			<div className='Main-content'>
				<UserContext.Provider value={{user, setuser}}>
				<Router>
					{sidenav.status && (
						<SideMenu
							close={handleClose}
							page={sidenav.page}
							marketer={sidenav.marketer}
							sysclicked={handleSystemClick}
							systemgps={sidenav.systemgps}
							thesystem={sidenav.thesystem}
						/>
					)}
					{revealmenu.status && (
						<RevealMenu
							system={revealmenu.system}
							action={revealmenu.action}
							close={handleRevealClose}
							edited={handleEdits}
							edited2={handleSystemClick}
						/>
					)}
					{showBackdrop && (
						<Backdrop
							pend={pend}
							reject={reject}
							closebackdrop={handleCloseModal}
							sysid={verId}
							clearverid={clearVerId}
							syschange={changeSysState}
						/>
					)}
					<Switch>
						{/* <UserContext.Provider >	 */}
						<Route exact path='/'>
							<Homepage user={user} />
						</Route>
						<Route exact path='/Analysis'>
							<Analyses user={user} />
						</Route>
						<Route path='/Map'
							component={Dashboard}
							isAuth={user.isAuth}
							user={user}>
						</Route>
						
						<ProtectedRoute
							path='/Downloads'
							component={Download}
							isAuth={user.isAuth}
							user={user}
						/>
						<ProtectedRoute
							path='/indicatorreporting'
							component={FeedData}
							isAuth={user.isAuth}
							user={user}
						/>
						<Route exact
							path='/Verification'>
							<Verification isAuth={user.isAuth}
							nav={changeNavState}
							tempApproval={tempApproval}
							onesystem={onesystem}
							handlePend={handlePend}
							handleReject={handleReject}
							sysstate={sysChanged}
							user={user}
							handleReveal={handleRevealCLick}
							handleMap={showMap}
							handleSan={showSanitation}
							edited={edited}
							editedfetched={handleEdits}/>
							

							</Route>
						
						<ProtectedRoute
							path='/UserSetup'
							component={UserSetup}
							isAuth={user.isAuth}
						/>
						{/* <Route
							path='/Analysis'
							component={Analyses}
							isAuth={user.isAuth}
							user={user}
						/> */}
						<ProtectedRoute
							path='/Meetings'
							component={Meetings}
							isAuth={user.isAuth}
							user={user}
						/>
						<Route exact path='*'>
							<Analyses user={user} />
						</Route>
						{/* </UserContext.Provider> */}
					</Switch>
				</Router>

				</UserContext.Provider>
			</div>
			<div className='mobile-content'>
				<h2>To use the app, kindly log in using a laptop/desktop</h2>
			</div>
		</div>
	);
}

export default App;
