import { useState } from "react";
import ApprovalModal from "./Verification/VerificationContainers/Verification-Box/Approval/ApprovalModal";
const Backdrop = ({show,pend,reject,closebackdrop,sysid,clearverid,syschange}) => {
    const [showBackdrop, setshowBackdrop] = useState(true)
    return (
        <div className="backdrop">
            {pend.show && <ApprovalModal pend={pend} reject={reject} closebackdrop={closebackdrop} sysid={sysid} clearverid={clearverid} syschange={syschange}/>}
            {reject.show && <ApprovalModal pend={pend} reject={reject} closebackdrop={closebackdrop} sysid={sysid} clearverid={clearverid} syschange={syschange}/>}
        </div>
        
     );
}
 
export default Backdrop;