import React, { useState, useEffect, useRef } from "react";
import { GrClose } from "react-icons/gr";
import { AiOutlineDown } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { BsImages } from "react-icons/bs";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { IoWarningOutline } from "react-icons/io5";
import editimage from "../../images/4.png";
import financingimage from "../../images/6.png";
import {
	Chart as CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CgDanger } from "react-icons/cg";
import { FcNext } from "react-icons/fc";

const RevealMenu = ({ close, action, system, edited, edited2 }) => {
	const [showing0, setshowing0] = useState("link1");
	const [showing1, setshowing1] = useState("link1");
	const [showing2, setshowing2] = useState("link1");
	const [showing3, setshowing3] = useState("link1");
	const [showing4, setshowing4] = useState("link1");
	const [showing5, setshowing5] = useState("link1");
	const [thesystem, setthesystem] = useState(null);
	let images = [1, 2, 3, 4, 5];
	const [actual, setactual] = useState(false);
	const [editactual, seteditactual] = useState(false);
	const [saveactual, setsaveactual] = useState(false);
	const [savingactual, setsavingactual] = useState(false);
	const [savedactual, setsavedactual] = useState(false);

	const [actualdoors, setactualdoors] = useState(false);
	const [editactualdoors, seteditactualdoors] = useState(false);
	const [saveactualdoors, setsaveactualdoors] = useState(false);
	const [savingactualdoors, setsavingactualdoors] = useState(false);
	const [savedactualdoors, setsavedactualdoors] = useState(false);

	const [fetching, setfetching] = useState(true);
	//Toilet Type
	const [toilettype, settoilettype] = useState(null);
	const [edittype, setedittype] = useState(false);
	const [savetype, setsavetype] = useState(false);
	const [savingtype, setsavingtype] = useState(false);
	const [savedtype, setsavedtype] = useState(false);
	//Toilet Setting
	const [toiletsetting, settoiletsetting] = useState(null);
	const [editsetting, seteditsetting] = useState(false);
	const [savesetting, setsavesetting] = useState(false);
	const [savingsetting, setsavingsetting] = useState(false);
	const [savedsetting, setsavedsetting] = useState(false);
	//Toilet Doors
	const [toiletdoors, settoiletdoors] = useState(null);
	const [editdoors, seteditdoors] = useState(false);
	const [savedoors, setsavedoors] = useState(false);
	const [savingdoors, setsavingdoors] = useState(false);
	const [saveddoors, setsaveddoors] = useState(false);
	//Toilet Class
	const [toiletclass, settoiletclass] = useState(null);
	const [editclass, seteditclass] = useState(false);
	const [saveclass, setsaveclass] = useState(false);
	const [savingclass, setsavingclass] = useState(false);
	const [savedclass, setsavedclass] = useState(false);
	//Toilet Users
	const [toiletusers, settoiletusers] = useState(null);
	const [editusers, seteditusers] = useState(false);
	const [saveusers, setsaveusers] = useState(false);
	const [savingusers, setsavingusers] = useState(false);
	const [savedusers, setsavedusers] = useState(false);
	//Marketer
	const [toiletmarketer, settoiletmarketer] = useState(null);
	const [editmarketer, seteditmarketer] = useState(false);
	const [savemarketer, setsavemarketer] = useState(false);
	const [savingmarketer, setsavingmarketer] = useState(false);
	const [savedmarketer, setsavedmarketer] = useState(false);
	//Marketer Contact
	const [toiletmcont, settoiletmcont] = useState(null);
	const [editmcont, seteditmcont] = useState(false);
	const [savemcont, setsavemcont] = useState(false);
	const [savingmcont, setsavingmcont] = useState(false);
	const [savedmcont, setsavedmcont] = useState(false);
	//Artisan
	const [toiletartisan, settoiletartisan] = useState(null);
	const [editartisan, seteditartisan] = useState(false);
	const [saveartisan, setsaveartisan] = useState(false);
	const [savingartisan, setsavingartisan] = useState(false);
	const [savedartisan, setsavedartisan] = useState(false);
	//Artisan Contact
	const [toiletartcont, settoiletartcont] = useState(null);
	const [editartcont, seteditartcont] = useState(false);
	const [saveartcont, setsaveartcont] = useState(false);
	const [savingartcont, setsavingartcont] = useState(false);
	const [savedartcont, setsavedartcont] = useState(false);
	//Personal Contribution
	const [toiletpersonalcont, settoiletpersonalcont] = useState(null);
	const [editpersonalcont, seteditpersonalcont] = useState(false);
	const [savepersonalcont, setsavepersonalcont] = useState(false);
	const [savingpersonalcont, setsavingpersonalcont] = useState(false);
	const [savedpersonalcont, setsavedpersonalcont] = useState(false);
	//Friends Contribution
	const [toiletfriendcont, settoiletfriendcont] = useState(null);
	const [editfriendcont, seteditfriendcont] = useState(false);
	const [savefriendcont, setsavefriendcont] = useState(false);
	const [savingfriendcont, setsavingfriendcont] = useState(false);
	const [savedfriendcont, setsavedfriendcont] = useState(false);
	//NGO contribution
	const [toiletngocont, settoiletngocont] = useState(null);
	const [editngocont, seteditngocont] = useState(false);
	const [savengocont, setsavengocont] = useState(false);
	const [savingngocont, setsavingngocont] = useState(false);
	const [savedngocont, setsavedngocont] = useState(false);
	//In Kind contribution
	const [toiletikcont, settoiletikcont] = useState(null);
	const [editikcont, seteditikcont] = useState(false);
	const [saveikcont, setsaveikcont] = useState(false);
	const [savingikcont, setsavingikcont] = useState(false);
	const [savedikcont, setsavedikcont] = useState(false);
	//Other Amounts
	const [toiletothercost, settoiletothercost] = useState(null);
	const [editothercost, seteditothercost] = useState(false);
	const [saveothercost, setsaveothercost] = useState(false);
	const [savingothercost, setsavingothercost] = useState(false);
	const [savedothercost, setsavedothercost] = useState(false);
	//Friends Loan
	const [toiletfriendloan, settoiletfriendloan] = useState(null);
	const [editfriendloan, seteditfriendloan] = useState(false);
	const [savefriendloan, setsavefriendloan] = useState(false);
	const [savingfriendloan, setsavingfriendloan] = useState(false);
	const [savedfriendloan, setsavedfriendloan] = useState(false);
	//Self Help Loan
	const [toiletshloan, settoiletshloan] = useState(null);
	const [editshloan, seteditshloan] = useState(false);
	const [saveshloan, setsaveshloan] = useState(false);
	const [savingshloan, setsavingshloan] = useState(false);
	const [savedshloan, setsavedshloan] = useState(false);
	// Credit Group loan
	const [toiletcgloan, settoiletcgloan] = useState(null);
	const [editcgloan, seteditcgloan] = useState(false);
	const [savecgloan, setsavecgloan] = useState(false);
	const [savingcgloan, setsavingcgloan] = useState(false);
	const [savedcgloan, setsavedcgloan] = useState(false);
	//Bank Loan
	const [toiletbankloan, settoiletbankloan] = useState(null);
	const [editbankloan, seteditbankloan] = useState(false);
	const [savebankloan, setsavebankloan] = useState(false);
	const [savingbankloan, setsavingbankloan] = useState(false);
	const [savedbankloan, setsavedbankloan] = useState(false);

	const toilets = [
		"Ventilated Improved Single Pit",
		"Ventilated Improved Double Pit (VIDP)",
		"Flush toilet linked to a single leach pit",
		"Flush toilet linked to a double leach pit",
		"Flush toilet linked to a septic tank",
		"Flush toilet linked to a bio-digester",
		"Flush toilet linked to the condominium sewer",
		"Flush toilet linked to main sewer",
		"Rehabilitation",
	];
	const labels = ["Personal savings", "Loans", "Contributions"];
	const tsettings = [
		"Household",
		"School",
		"Rental Property",
		"Health Facility",
		"Market",
	];
	const tclass = ["Newly Constructed", "Rehabilitated"];
	const options = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: "Financing Distribution",
			},
		},
	};

	const [imageurl, setimageurl] = useState(
		JSON.parse(localStorage.getItem("Images")) || []
	);
	let link1 =
		"https://kc.datacollection.amref.org/media/original?media_file=judy_murithi%2Fattachments/";
	let link2 = "https://kc.datacollection.amref.org/media/original?media_file=";
	function getImages(system) {
		if (
			!JSON.parse(localStorage.getItem("Images")) ||
			JSON.parse(localStorage.getItem("Images")).length < 1
		) {
			setfetching(true);
			fetch("/api/v1/proximity/" + system, { credentials: "include" })
				.then((res) => {
					return res.json();
				})
				.then((toilets) => {
					setimageurl(toilets.data);
					localStorage.setItem("Images", JSON.stringify(toilets.data));
					setfetching(false);
				});
		}
	}
	// function changeName(thesystem){
	// let toiletname;
	// switch (thesystem.systype) {
	//     case 'septic_tank':
	//         toiletname='Septic Tank'
	//         break;
	//     case 'vidp':
	//         toiletname='Ventilated Improved Double Pit'
	//         break;
	//     case 'visp':
	//         toiletname='Ventilated Improved Single Pit'
	//         break;
	//     case 'single_leach_pit':
	//         toiletname='Single Leach Pit'
	//         break;
	//     case 'double_leach_pit':
	//         toiletname='Double Leach pit'
	//         break;
	//     default:
	//         break;
	// }
	// return toiletname
	// }
	function saveToiletType() {
		let body = {
			systype: toilettype,
		};
		setsavetype(false);
		setsavingtype(true);
		fetch("/api/v1/edit/toilettype/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingtype(false);
					setsavedtype(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveToiletSetting() {
		let body = {
			setting: toiletsetting,
		};
		setsavesetting(false);
		setsavingsetting(true);
		fetch("/api/v1/edit/setting/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingsetting(false);
					setsavedsetting(true);
					setthesystem(res.toilet);
					edited2(thesystem._id, res.approval);
				}
			});
	}
	function saveToiletDoors() {
		let body = {
			doors: toiletdoors,
		};
		setsavedoors(false);
		setsavingdoors(true);
		fetch("/api/v1/edit/doors/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingdoors(false);
					setsaveddoors(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveToiletClass() {
		let body = {
			class: toiletclass,
		};
		setsaveclass(false);
		setsavingclass(true);
		fetch("/api/v1/edit/class/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingclass(false);
					setsavedclass(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveToiletUsers() {
		let body = {
			users: toiletusers,
		};
		setsaveusers(false);
		setsavingusers(true);
		fetch("/api/v1/edit/users/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingusers(false);
					setsavedusers(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveToiletMarketer() {
		let body = {
			marketer: toiletmarketer,
		};
		setsavemarketer(false);
		setsavingmarketer(true);
		fetch("/api/v1/edit/marketer/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingmarketer(false);
					setsavedmarketer(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}

	function saveMarketerContact() {
		let body = {
			mcont: toiletmcont,
		};
		setsavemcont(false);
		setsavingmcont(true);
		fetch("/api/v1/edit/mcont/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingmcont(false);
					setsavedmcont(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveToiletArtisan() {
		let body = {
			artisan: toiletartisan,
		};
		setsaveartisan(false);
		setsavingartisan(true);
		fetch("/api/v1/edit/artisan/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingartisan(false);
					setsavedartisan(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveArtisanContact() {
		let body = {
			artcont: toiletartcont,
		};
		setsaveartcont(false);
		setsavingartcont(true);
		fetch("/api/v1/edit/artisancont/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingartcont(false);
					setsavedartcont(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function savePersonalCont() {
		let body = {
			personalCont: toiletpersonalcont,
		};
		setsavepersonalcont(false);
		setsavingpersonalcont(true);
		fetch("/api/v1/edit/personalcont/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingpersonalcont(false);
					setsavedpersonalcont(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveFriendCont() {
		let body = {
			friendsCont: toiletfriendcont,
		};
		setsavefriendcont(false);
		setsavingfriendcont(true);
		fetch("/api/v1/edit/friendcont/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingfriendcont(false);
					setsavedfriendcont(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveNGOCont() {
		let body = {
			ngoCont: toiletngocont,
		};
		setsavengocont(false);
		setsavingngocont(true);
		fetch("/api/v1/edit/ngocont/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingngocont(false);
					setsavedngocont(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveInKind() {
		let body = {
			inkind: toiletikcont,
		};
		setsaveikcont(false);
		setsavingikcont(true);
		fetch("/api/v1/edit/inkind/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingikcont(false);
					setsavedikcont(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveOtherAmount() {
		let body = {
			othercost: toiletothercost,
		};
		setsaveothercost(false);
		setsavingothercost(true);
		fetch("/api/v1/edit/othercost/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingothercost(false);
					setsavedothercost(true);
					edited2(thesystem._id, res.approval);

					setthesystem(res.toilet);
				}
			});
	}
	function saveFriendLoan() {
		let body = {
			friendloan: toiletfriendloan,
		};
		setsavefriendloan(false);
		setsavingfriendloan(true);
		fetch("/api/v1/edit/friendloan/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingfriendloan(false);
					setsavedfriendloan(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveSHLoan() {
		let body = {
			shloan: toiletshloan,
		};
		setsaveshloan(false);
		setsavingshloan(true);
		fetch("/api/v1/edit/shloan/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingshloan(false);
					setsavedshloan(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveCGLoan() {
		let body = {
			cgloan: toiletcgloan,
		};
		setsavecgloan(false);
		setsavingcgloan(true);
		fetch("/api/v1/edit/cgloan/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingcgloan(false);
					setsavedcgloan(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveBankLoan() {
		let body = {
			bankloan: toiletbankloan,
		};
		setsavebankloan(false);
		setsavingbankloan(true);
		fetch("/api/v1/edit/bankloan/" + thesystem._id, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingbankloan(false);
					setsavedbankloan(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveActualCost() {
		setsavingactual(true);
		setactual(false);
		fetch("/api/v1/edit/saveactual/" + thesystem._id, {
			method: "POST",
			credentials: "include",
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingactual(false);
					setsavedactual(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function saveActualDoors() {
		setsavingactualdoors(true);
		setactualdoors(false);
		fetch("/api/v1/edit/saveactualdoors/" + thesystem._id, {
			method: "POST",
			credentials: "include",
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.done) {
					setsavingactualdoors(false);
					setsavedactualdoors(true);
					edited2(thesystem._id, res.approval);
					setthesystem(res.toilet);
				}
			});
	}
	function loadSystemDetails(system) {
		if (action != "similar") {
			fetch("/api/v1/onesystem/" + system, {
				method: "GET",
				credentials: "include",
			})
				.then((res) => res.json())
				.then((system) => {
					setthesystem(system.toilet[0]);
					setfetching(false);
				});
		} else {
			getImages(system);
		}
	}
	function getSource(i) {
		let thesource;
		switch (i) {
			case 0:
				thesource = showing0;
				break;
			case 1:
				thesource = showing1;
				break;
			case 2:
				thesource = showing2;
				break;
			case 3:
				thesource = showing3;
				break;
			case 4:
				thesource = showing4;
				break;
			case 5:
				thesource = showing5;
				break;
			default:
				break;
		}
		return thesource;
	}
	function next(i) {
		switch (i) {
			case 0:
				setshowing0("link2");
				break;
			case 1:
				setshowing1("link2");
				break;
			case 2:
				setshowing2("link2");
				break;
			case 3:
				setshowing3("link2");
				break;
			case 4:
				setshowing4("link2");
				break;
			case 5:
				setshowing5("link2");
				break;
		}
	}
	function prev(i) {
		switch (i) {
			case 0:
				setshowing0("link1");
				break;
			case 1:
				setshowing1("link1");
				break;
			case 2:
				setshowing2("link1");
				break;
			case 3:
				setshowing3("link1");
				break;
			case 4:
				setshowing4("link1");
				break;
			case 5:
				setshowing5("link1");
				break;
		}
	}
	useEffect(() => {}, [action]);
	useEffect(() => {}, [thesystem]);
	useEffect(() => {
		if (system) {
			loadSystemDetails(system);
		}
	}, []);
	return (
		<div className='reveal-menu'>
			<div className='reveal-close' onClick={() => close()}>
				<GrClose />
			</div>
			{action && action == "edit" && (
				<div className='reveal-edit-container'>
					<div className='reveal-container-1'>
						<div className='reveal-edit-box'>
							<FiEdit3 className='reveal-edit-icon' />
							<h3>Edit Toilet Details.</h3>
							<p>
								This menu allows you to edit details about a toilet after it has
								been reported in the field.
							</p>
						</div>
					</div>
					{fetching && (
						<>
							<div className='reveal-container-2'>
								<div className='systypediv-skel'></div>
								<div className='systypediv-skel'></div>
								<div className='systypediv-skel'></div>
							</div>
							<div className='reveal-container-3'>
								<div className='systypediv-skel'></div>
								<div className='systypediv-skel'></div>
								<div className='systypediv-skel'></div>
							</div>
						</>
					)}
					{thesystem && (
						<div className='reveal-container-2'>
							<h5>Toilet Details</h5>

							<div className='sysedit'>
								<div className='systypediv' onClick={(e) => setedittype(true)}>
								<FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Toilet Type</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={toilettype || thesystem.systype}
									  label="Toilet Type"
									  onChange={e=>{settoilettype(e.target.value);
										setedittype(false);
										setsavedtype(false);
										setsavetype(true);}}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{toilets.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
								</div>
								{savetype && (
									<span
										onClick={(e) => {
											saveToiletType();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingtype && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedtype && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<div className='sysedit'>
								<div
									className='systypediv'
									onClick={(e) => seteditsetting(true)}>
									<FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Toilet Setting</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={toiletsetting || thesystem.setting}
									  label="Toilet Setting"
									  onChange={e=>{settoiletsetting(e.target.value);
										seteditsetting(false);
										setsavedsetting(false);
										setsavesetting(true);}}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{tsettings.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
								</div>
								{savesetting && (
									<span
										onClick={(e) => {
											saveToiletSetting();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingsetting && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedsetting && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<div className='sysedit'>
								<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type='number' placeholder={`Number of Doors: ${thesystem.sysDoors} ${
										system.susDoors ? (
											<span className='suspect-value'>
												<CgDanger />
											</span>
										) : (
											""
										)
									}`}
									onChange={(e) => {
										setsavedoors(true);
										settoiletdoors(e.target.value);
										setsaveddoors(false);
									}}/>

								</div>
								{thesystem.suspectDoors && (
									<p>
										<span className='suspect-value'>
											<CgDanger />
										</span>
									</p>
								)}
								{savedoors && (
									<span
										onClick={(e) => {
											saveToiletDoors();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingdoors && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{saveddoors && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							{thesystem && thesystem.suspectDoors && (
								<div className='confirm-cost'>
									<input
										type='checkbox'
										onChange={(e) => setactualdoors(!actualdoors)}></input>
									<p>This is the actual toilet doors number.</p>
									{actualdoors && (
										<span
											onClick={(e) => {
												saveActualDoors();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingactualdoors && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedactualdoors && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							<div className='sysedit'>
								<div className='systypediv' onClick={(e) => seteditclass(true)}>
								<FormControl sx={{minWidth: '200px',fontSize:'12px'}} size="medium" className="formctrl">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>Toilet Class</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={toiletclass || thesystem.sysClass}
									  label="Toilet Class"
									  onChange={e=>{settoiletclass(e.target.value);
										seteditclass(false);
										setsavedclass(false);
										setsaveclass(true);}}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{tclass.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
								</div>
								{saveclass && (
									<span
										onClick={(e) => {
											saveToiletClass();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingclass && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedclass && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<div className='sysedit'>
							<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type='number'
									placeholder={`Number of Users: ${thesystem.sysUsers} ${
										system.susUsers ? (
											<span className='suspect-value'>
												<CgDanger />
											</span>
										) : (
											""
										)
									}`}
									onChange={(e) => {
										setsaveusers(true);
										settoiletusers(e.target.value);
										setsavedusers(false);
									}}/>

								</div>
								{thesystem.suspectUsers && (
									<p>
										<span className='suspect-value'>
											<CgDanger />
										</span>
									</p>
								)}
								{saveusers && (
									<span
										onClick={(e) => {
											saveToiletUsers();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingusers && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedusers && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<h5>Marketer Details</h5>
							<div className='sysedit'>
							<div className="systypediv">
							<TextField id="standard-basic"  variant="standard"
									placeholder={`Marketer Name: ${thesystem.marketer}`}
									onChange={(e) => {
										setsavemarketer(true);
										settoiletmarketer(e.target.value);
										setsavedmarketer(false);
									}}/>

								</div>
								{savemarketer && (
									<span
										onClick={(e) => {
											saveToiletMarketer();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingmarketer && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedmarketer && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<div className='sysedit'>
							<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Marketer Contact: 0${thesystem.markCont}`}
									onChange={(e) => {
										setsavemcont(true);
										settoiletmcont(e.target.value);
										setsavedmcont(false);
									}}/>

								</div>
								{savemcont && (
									<span
										onClick={(e) => {
											saveMarketerContact();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{thesystem.markCOntSus && (
									<p>
										<span className='suspect-value'>
											<CgDanger />
										</span>
									</p>
								)}
								{savingmcont && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedmcont && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<h5>Artisan Details</h5>
							<div className='sysedit'>
							<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" 
									placeholder={`Artisan Name: ${thesystem.artName}`}
									onChange={(e) => {
										setsaveartisan(true);
										settoiletartisan(e.target.value);
										setsavedartisan(false);
									}}/>

								</div>
								{saveartisan && (
									<span
										onClick={(e) => {
											saveToiletArtisan();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{savingartisan && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedartisan && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
							<div className='sysedit'>
							<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Artisan Contact: 0${thesystem.artCont}`}
									onChange={(e) => {
										setsaveartcont(true);
										settoiletartcont(e.target.value);
										setsavedartcont(false);
									}}/>

								</div>
								{saveartcont && (
									<span
										onClick={(e) => {
											saveArtisanContact();
										}}>
										<p className='editsave'>save details.</p>
									</span>
								)}
								{thesystem.susArtCont && (
									<p>
										<span className='suspect-value'>
											<CgDanger />
										</span>
									</p>
								)}
								{savingartcont && (
									<span>
										<p className='editsave'>saving...</p>
									</span>
								)}
								{savedartcont && (
									<span>
										<p className='editsave'>saved!</p>
									</span>
								)}
							</div>
						</div>
					)}
					{thesystem && (
						<div className='reveal-container-3'>
							<h5>Toilet Financing</h5>
							<h5>
								Total Cost:{" "}
								<span className='systypedivspan'>{thesystem.totCost}</span>{" "}
								{thesystem.susCost ? (
									<span className='suspect-value'>
										<CgDanger />
									</span>
								) : (
									""
								)}{" "}
								{thesystem.gvt ? "Government/Grant" : ""}
							</h5>
							{thesystem && thesystem.susCost && (
								<div className='confirm-cost'>
									<input
										type='checkbox'
										onChange={(e) => setactual(!actual)}></input>
									<p>This is the actual cost of toilet</p>
									{actual && (
										<span
											onClick={(e) => {
												saveActualCost();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingactual && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedactual && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.personalCont && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Personal Contribution: ${thesystem.personalCont}`}
									onChange={(e) => {
										setsavepersonalcont(true);
											settoiletpersonalcont(e.target.value);
											setsavedpersonalcont(false);
									}}/>

								</div>
									{savepersonalcont && (
										<span
											onClick={(e) => {
												savePersonalCont();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingpersonalcont && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedpersonalcont && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.friendsCont && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Friends' Contribution: ${thesystem.friendsCont}`}
									onChange={(e) => {
										setsavefriendcont(true);
										settoiletfriendcont(e.target.value);
										setsavedfriendcont(false);
									}}/>

								</div>
									{savefriendcont && (
										<span
											onClick={(e) => {
												saveFriendCont();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingfriendcont && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedfriendcont && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.ngoAmount && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`NGO Support Amount: ${thesystem.ngoAmount}`}
									onChange={(e) => {
										setsavengocont(true);
											settoiletngocont(e.target.value);
											setsavedngocont(false);
									}}/>

								</div>
									{savengocont && (
										<span
											onClick={(e) => {
												saveNGOCont();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingngocont && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedngocont && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.inKindAmount && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`In Kind Amount: ${thesystem.inKindAmount}`}
									onChange={(e) => {
										setsaveikcont(true);
											settoiletikcont(e.target.value);
											setsavedikcont(false);
									}}/>

								</div>
									{saveikcont && (
										<span
											onClick={(e) => {
												saveInKind();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingikcont && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedikcont && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.costOtherAmount && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Other Sources: ${thesystem.costOtherAmount}`}
									onChange={(e) => {
										setsaveothercost(true);
											settoiletothercost(e.target.value);
											setsavedothercost(false);
									}}/>

								</div>
									{saveothercost && (
										<span
											onClick={(e) => {
												saveOtherAmount();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingothercost && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedothercost && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.friendsLoan && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Loan From Friends: ${thesystem.friendsLoan}`}
									onChange={(e) => {
										setsavefriendloan(true);
											settoiletfriendloan(e.target.value);
											setsavedfriendloan(false);
									}}/>

								</div>
									{savefriendloan && (
										<span
											onClick={(e) => {
												saveFriendLoan();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingfriendloan && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedfriendloan && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.selfhelpLoan && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Loan From Self-Help Groups: ${thesystem.selfhelpLoan}`}
									onChange={(e) => {
										setsaveshloan(true);
											settoiletshloan(e.target.value);
											setsavedshloan(false);
									}}/>

								</div>
									{saveshloan && (
										<span
											onClick={(e) => {
												saveSHLoan();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingshloan && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedshloan && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.creditGroupLoan && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Loan From Credit Groups: ${thesystem.creditGroupLoan}`}
									onChange={(e) => {
										setsavecgloan(true);
											settoiletcgloan(e.target.value);
											setsavedcgloan(false);
									}}/>
								</div>
									{savecgloan && (
										<span
											onClick={(e) => {
												saveCGLoan();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingcgloan && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedcgloan && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
							{thesystem.bankLoan && (
								<div className='sysedit'>
									<div className="systypediv">
							<TextField id="standard-basic"  variant="standard" type="number"
									placeholder={`Loan From Bank: ${thesystem.bankLoan}`}
									onChange={(e) => {
										setsavebankloan(true);
											settoiletbankloan(e.target.value);
											setsavedbankloan(false);
									}}/>
								</div>
									{savebankloan && (
										<span
											onClick={(e) => {
												saveBankLoan();
											}}>
											<p className='editsave'>save details.</p>
										</span>
									)}
									{savingbankloan && (
										<span>
											<p className='editsave'>saving...</p>
										</span>
									)}
									{savedbankloan && (
										<span>
											<p className='editsave'>saved!</p>
										</span>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{action && action == "similar" && (
				<div className='reveal-edit-container'>
					<div className='reveal-container-1'>
						<div className='reveal-finance-box'>
							<BsImages className='reveal-edit-icon' />
							<h3>Similar Toilets</h3>
							<p>
								This menu shows you similar toilets ranked by proximity from
								this toilet.
							</p>
						</div>
					</div>
					{imageurl.length < 1 &&
						fetching &&
						images.map((image) => (
							<div className='sim-image' key={image}></div>
						))}
					{imageurl.length < 1 && !fetching && (
						<div className='reveal-container-1'>
							<h4>No Similar Toilets Found</h4>
						</div>
					)}
					{imageurl.length > 0 &&
						imageurl.map((image, index) => (
							<div className='sim-image' key={image._id}>
								{image.version == "v1" && (
									<img src={link1 + image[getSource(index)]}></img>
								)}
								{image.version == "v2" && (
									<img src={link2 + image[getSource(index)]}></img>
								)}
								<div className='prev-sim-image' onClick={(e) => prev(index)}>
									<GrFormPreviousLink className='next-sim-icon' />
								</div>
								<div className='next-sim-image' onClick={(e) => next(index)}>
									<GrFormNextLink className='next-sim-icon' />
								</div>
							</div>
						))}
				</div>
			)}
		</div>
	);
};
export default RevealMenu;
