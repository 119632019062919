import Navbar from "../../containers/Navbar/Navbar";
import React, {
	useState,
	createContext,
} from "react";
import "./Analyses.css";
import Callout from "./Callout";
import ToiletCost from "./ToiletCost";
import FiltersPane from "./FIlters";
import Progress from "./Progressgraph";
import ToiletType from "./ToiletType";
import Setting from './Setting'
import Constructed from "./ConstvsRehab";
import Marketer from "./ByMarketer";
import UnstyledSnackbarIntroduction from './Snackbar'
export const filtersContext=createContext()

const Analyses = ({ user }) => {
const [filters,setfilters]=useState({country:'Kenya',county:['Kisumu','Nakuru','Kitui','Embu','Meru','Tharaka Nithi','Homa Bay'],month:null,year:'2024',approval:'Approved',state:'All Toilets'})
	return (
		<div>
			<Navbar user={user} />
			<div className='first-element'>
			</div>
			<filtersContext.Provider value={{filters,setfilters}}>
				<FiltersPane/>
				{/* <UnstyledSnackbarIntroduction/> */}
				<div className="graphpane">
					<div className="firstgraph">
						<div className='calloutcard'>
							<Callout/>
						</div>
						<div className='progressgraph'>
							<Progress/>
						</div>
						
						<div className='progressgraph'>
							<Setting/>
						</div>
					</div>
					<div className="secondgraph">
					<div className='progressgraph'>
							<ToiletCost/>
						</div>
					
						{filters.country=='Kenya' &&
						<div className='secondgraphactual'>
							<Marketer/>
						</div>}
					</div>
					<div className='thirdgraph'>
					<div className='progressgraph'>
							<ToiletType/>
						</div>
					</div>
				</div>
			</filtersContext.Provider>
					
		</div>
	);
};

export default Analyses;
