import React from 'react'
import {Route,Redirect} from 'react-router-dom'
function ProtectedRoute({isAuth:isAuth,component:Component,...rest}){
    return (
    <Route render={(props)=>{
        if(isAuth){
            return <Component {...rest}/>
        }else{
           return <Redirect to={{pathname:'/', state:{from:props.location}}}/>
        }
    }
    }/>
    )
}
 
export default ProtectedRoute;