import React, { useState, useEffect } from 'react';
import Navbar from "../../containers/Navbar/Navbar";
import './Meeting.css'
import {BsCalendar3,BsPeople} from "react-icons/bs";
import {FaLocationArrow} from "react-icons/fa";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const Meeting=({user})=>{
    const images=[1,2,3]
    const data = {
        labels: ['Male', 'Female','Other'],
        datasets: [
          {
            label: '# of Votes',
            data: [12, 17,3],
            backgroundColor: [
              'rgb(126, 185, 103, 0.2)',
              'rgba(54, 162, 235, 0.2)',              
              'rgb(201, 151, 201, 0.2)'              
            ],
            borderColor: [
              'rgb(126, 185, 103, 1)',
              'rgba(54, 162, 235, 1)',
              'rgb(201, 151, 201,1)'
            ],
            borderWidth: 1,
          },
        ],
      };
return(
    <div>
        <Navbar user={user}/>
        <div className='meeting-main'>
            <div className='meeting-left-col'>
                <div className='meeting-search'>
                    <input className='meeting-search-bar' type='text' placeholder='Search for a meeting'></input>
                </div>
                <div className='meeting-list'>
                    <div className='meeting-list-item'>
                        <div className='list-item-icon'>
                            <BsCalendar3 className='the-icon'/>
                        </div>
                        <div className='list-item-details'>
                            <h2>Marketers training Meeting...</h2>  
                            <p>Training program</p>                          
                            {/* <h3>12/10/2022</h3> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='meeting-right-col'>
                <div className='meeting-header'>
                    <h2><BsCalendar3/> Marketers training Meeting on Improved Toilet Facilities.</h2>
                </div>
                <div className='meeting-main-content'>
                    <div className='meeting-details-container'>
                    <div className='meeting-details'>
                        <div className='people'>
                            <div><BsPeople/></div>
                            <div className='person'>Beril Abiya</div>
                            <div className='person'>Mildred Elsie</div>
                            <div className='person'>Winfred Kubania</div>
                        </div>
                        <div className='description'>
                            <h5>Meeting Description</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div className='venue'>
                            <div className='pin'><FaLocationArrow/></div>
                            <div className='location'>Park Inn Hotel, Kitui, Kitui Town</div>
                        </div>
                    </div>
                    <div className='meeting-chart'>
                    <div>
                        {/* <p>Meeting Attendance</p> */}
                    </div>
                    <Doughnut data={data}/>;
                    </div>
                    </div>
                    <div className='meeting-files-container'>
                        <div className='files'>
                        <div className='upload-report'>
                            <input type='file'></input>
                        </div>
                        <div className='download-excel'>
                            <div></div>
                            <div>Download Excel</div>
                            
                        </div>
                        </div>
                        <div className='images'>
                            {images && images.map(image=>(
                                <img src={images}></img>
                            ))}                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}
export default Meeting;