import React, { useState, useEffect } from 'react';
// import { userAuth } from '../../../../../../server/Middleware/isUser';
import { BiAccessibility } from "react-icons/bi";
import { BsStarFill } from "react-icons/bs";
import waiting from '../../../../images/waiting.png'

const VerificationImage = ({ system ,preview,user}) => {
	let imageclass='verification-image'
	const imgclasses = [
		{ class: 'image-indicator3', image: 3 },
		{ class: 'image-indicator2', image: 2 },
		{ class: 'image-indicator1', image: 1 },
	];
	const imgclasses2 = [
		{ class: 'image-indicator2', image: 2 },
		{ class: 'image-indicator1', image: 1 },
	];
	const [imagenum, setimagenum] = useState('link1');
	const [imgindicator, setImgindicator] = useState(imgclasses);
	const [imgindicator2, setImgindicator2] = useState(imgclasses2);
	function moveClasses(classnames, indexfrom, indexto) {
		let system = classnames[indexfrom];
		classnames.splice(indexfrom, 1);
		classnames.splice(indexto, 0, system);
	}
	function handleNumChange() {
		setimagenum('link1');
		setImgindicator(imgclasses);
		setImgindicator2(imgclasses2);
	}
	const handleImageClick = () => {
		if (system.link3) {
			switch (imagenum) {
				case 'link1':
					moveClasses(imgclasses, 1, 2);
					setImgindicator(imgclasses);
					setimagenum('link2');
					break;
				case 'link2':
					moveClasses(imgclasses, 0, 2);
					moveClasses(imgclasses, 1, 0);
					setImgindicator(imgclasses);
					setimagenum('link3');
					break;
				case 'link3':
					setImgindicator(imgclasses);
					setimagenum('link1');
					break;
				default:
					break;
			}
		}else {
			switch (imagenum) {
				case 'link1':
					moveClasses(imgclasses2, 0, 1);
					setImgindicator2(imgclasses2);
					setimagenum('link2');
					break;
				case 'link2':
					setImgindicator2(imgclasses2);
					setimagenum('link1');
					break;
				default:
					break;
			}
		}
	};
	useEffect(() => {
		return;
	}, [imagenum]);
	useEffect(() => {
		return;
	}, [imgindicator]);
	useEffect(() => {
		return;
	}, [imgindicator2]);
	useEffect(()=>{
		return
	},[system])
	let imagesource
	if(system && !user.isAuth && system.version=='v2'){
		imagesource='https://kc.datacollection.amref.org/media/original?media_file='+system[imagenum];
	}else if(system && user.user.country=="Kenya" && system.version=='v1'){
		 imagesource ='https://kc.datacollection.amref.org/media/original?media_file=judy_murithi%2Fattachments/' +system[imagenum];

	}else if(system && user.user.country=="Uganda"){
		imagesource ='https://kc.datacollection.amref.org/media/original?media_file=' +system[imagenum];
	}else if(system && user.user.country=="Kenya" && system.version=='v2'){
		imagesource='https://kc.datacollection.amref.org/media/original?media_file='+system[imagenum];
	}
	
	
	return (
		<div className={`verification-image${preview? "-preview" :""}`}>
			{system.link1 && system.sysClass=='Newly Constructed' && <div className='newlyconst'>
				<h4 className='newlyconsth4'>Newly constructed</h4></div>}
			{system.link1 && system.sysClass=='Rehabilitated' && <div className='rehabilitated'>
				<h4 className='newlyconsth4'>Rehabilitated</h4></div>}
			{system.link1 && 
			<img  
				src={imagesource || handleNumChange()}
				className='image1'
				onClick={() => handleImageClick()}
			/>}
			{system && system.link1 && system.link3 &&
				imgindicator.map((img,index) => (
					<div className={img.class} key={index}>
						<h3>{img.image}</h3>
					</div>
				))}
			{system && system.link1 && !system.link3 &&
				imgindicator2.map((img,index) => (
					<div key={index} className={img.class}>
						<h3>{img.image}</h3>
					</div>
				))}
			{!system.link1 &&
			<div className='loading-images'>

			</div>
		}
			
		</div>
	);
};

export default VerificationImage;
