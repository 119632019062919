import VerificationMarketer from "../../Marketer-Details/VerificationMarketer";
import SystemDetails from "./System-Details/SystemDetails";
import Approval from "./Approval/ApprovalButtons";
import ApprovalModal from './Approval/ApprovalModal'
import React, { useState, useEffect } from 'react';


const VerificationBox = ({system,approve,marketer,handleSkip,ttapprove,handleReject,handlePend,reloadsystems,user,handleReveal,handleMap,handleSan,handleNext,handlePrev}) => {
   const [updatesystem, setupdatesystem] = useState(false)
   const [show,setshow]=useState(false)
   function handleUpdateActionSystem(){
       setupdatesystem(!updatesystem)
   }
   function ChangeShow(){
       setshow(false)
   }
   useEffect(() => {
       setshow(false)
       setTimeout(()=>setshow(true),3000)
   }, [system])
   
    return ( 
        <div className="verification-box">            
            <VerificationMarketer marketer={system} marketerclick={marketer} handleSkip={handleSkip}
			handleReveal={handleReveal} handleMap={handleMap}  handleSan={handleSan}  user={user}        
            />
            <SystemDetails system={system} updatesystem={updatesystem} reloadsystems={reloadsystems} user={user}/>            
            {system && show && <Approval approval={approve}  prev={handlePrev} next={handleNext} system={system} pend={handlePend} reject={handleReject} updatesystem={handleUpdateActionSystem} user={user} change={ChangeShow}/>}
            {system && !show && <Approval approval={approve}  system={system} pend={handlePend} reject={handleReject} updatesystem={handleUpdateActionSystem} user={user} waiting={true}/>}
        </div>
     );
}
 
export default VerificationBox;