import React, { useState, useEffect } from 'react';
import { BsFilter } from "react-icons/bs";
import { RiFilterOffLine } from 'react-icons/ri';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const Filter=({filter})=>{
    let years=[2022,2023,2024]
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const toilets = [
		"Ventilated Improved Single Pit",
		"Ventilated Improved Double Pit (VIDP)",
		"Flush toilet linked to a single leach pit",
		"Flush toilet linked to a double leach pit",
		"Flush toilet linked to a septic tank",
		"Flush toilet linked to a bio-digester",
		"Flush toilet linked to the condominium sewer",
		"Flush toilet linked to main sewer",
		"Rehabilitation",
	];
    const [year, setyear] = useState(JSON.parse(localStorage.getItem('year')) || null)
    const [yearclicked, setyearclicked] = useState(null)
    const [month, setmonth] = useState(JSON.parse(localStorage.getItem('month'))||null)
    const [monthclicked, setmonthclicked] = useState(null)
    const [toilet, settoilet] = useState(JSON.parse(localStorage.getItem('toilet'))||null)
    const [toiletclicked, settoiletclicked] = useState(null)
    // Functions
    function clickedItem(item){
        switch (item) {
            case 'year':
                if(yearclicked){
                    setyearclicked(false)
                }else{
                    setyearclicked(true)
                    setmonthclicked(false)
                    settoiletclicked(false)
                }
                break;
                case 'month':
                if(monthclicked){
                    setmonthclicked(false)
                }else{
                    setmonthclicked(true)
                    setyearclicked(false)
                    settoiletclicked(false)
                }
                break;
                case 'toilet':
                if(toiletclicked){
                    settoiletclicked(false)
                }else{
                    settoiletclicked(true)
                    setyearclicked(false)
                    setmonthclicked(false)
                }
                break;
            default:
                break;
        }
       
    }
    function clearDropdowns(){
        settoiletclicked(false)
                    setyearclicked(false)
                    setmonthclicked(false)
    }
    function clearFilters(){
        setmonth(null)
        setyear(null)
        settoilet(null)
    }
   useEffect(() => {
    //   setmonth(null)
    //   setyear(null)
    //   settoilet(null)
    }, [])
    useEffect(() => {
        localStorage.setItem('year',JSON.stringify(year))
    },[year])
    useEffect(() => {
        localStorage.setItem('month',JSON.stringify(month))
    },[month])
    useEffect(() => {
        localStorage.setItem('toilet',JSON.stringify(toilet))
    },[toilet])
    return(
        <div className='toilet-filters'>
            <div >
            <FormControl sx={{minWidth: '200px',fontSize:'12px'}}className="formctrl ">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}} className='year-filter'>Filter Year</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={year}
									  label="Filter Year"
									  onChange={e=>{setyear(e.target.value)}}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{years.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
            {/* <div className='filter-item' onClick={e=>clickedItem('year')}>
                <p className='filter-text'>{year && <>Selected: <span className='selected-filter'>{year}</span></> || 'Filter By Year' }</p>
            </div>
            <div className='drop-filter'>
                {yearclicked && years.map(year=>(
                    <p onClick={e=>{setyear(year) 
                    clearDropdowns()}}>{year}</p>
                ))}
            </div>  */}
            </div>
            <div>
            <FormControl sx={{minWidth: '200px',fontSize:'12px'}}className="formctrl ">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}} >Filter Month</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={month}
									  label="Filter Month"
									  onChange={e=>{setmonth(e.target.value)}}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{months.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
            {/* <div className='filter-item' onClick={e=>clickedItem('month')}>
                <p className='filter-text'>{month && <>Selected: <span className='selected-filter'>{month}</span></> || 'Filter By Month'}</p>
            </div>
            <div className='drop-filter'>
                {monthclicked && months.map(month=>(
                    <p onClick={e=>{setmonth(month) 
                        clearDropdowns()}}>{month}</p>
                ))}
            </div>  */}
            </div>
            <div>
            <FormControl sx={{minWidth: '200px',fontSize:'12px'}}className="formctrl ">
								<InputLabel id="statefilter" sx={{fontSize:'12px', fontFamily:'Cera Pro2'}} >Filter Toilets</InputLabel>
									<Select
									  labelId="statefilter"
									  id="yearfilterid"
									  value={toilet}
									  label="Filter Toilets"
									  onChange={e=>{settoilet(e.target.value)}}
									  sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>
									{toilets.map((c,i)=>(
									  <MenuItem value={c} key={i} sx={{fontSize:'12px', fontFamily:'Cera Pro2'}}>{c}</MenuItem>
									))}
									</Select>
						 		 </FormControl>
            {/* <div className='filter-item' onClick={e=>clickedItem('toilet')}>
                 <p className='filter-text'>{toilet && <>Selected: <span className='selected-filter'>{toilet}</span></> || 'Filter By Toilet'}</p>
            </div>
            <div className='drop-filter'>
                {toiletclicked && toilets.map(toilet=>(
                    <p onClick={e=>{settoilet(toilet) 
                        clearDropdowns()}}>{toilet}</p>
                ))}
            </div>  */}
            </div>
            <div className='filter' onClick={e=>{filter(year,month,toilet)}}>
                <h2 ><BsFilter/></h2>
            </div>
            <div className='clear-filters' onClick={e=>{clearFilters()}}>
                <h2 ><RiFilterOffLine/></h2>
            </div>
        </div>
    )
}
export default Filter;