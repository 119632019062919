import { useEffect,useState } from "react";
import Modal from './Modal'
const ApprovalModal = ({pend,reject,closebackdrop,clearverid,sysid,syschange}) => {    
        const [modalshow, setShow] = useState(false);
        const handleClose = () => {
            closebackdrop()
            clearverid()
            setShow(false);
        }
        const [approval, setapproval] = useState('')
        const [approval2, setapproval2] = useState('Pending')
        
        useEffect(()=>{
            if(pend.show){
                setapproval('Pending')
                setapproval2('Pending')
            }
            else if(reject.show){
                setapproval('Rejected')
                setapproval2('Rejecting')
            }
            setShow(true)
        },[pend,reject])
        useEffect(()=>{
            return
        },[modalshow])
        return (
          <div>
             {modalshow && <Modal close={handleClose} approval={approval2} sysid={sysid} syschange={syschange}/>}
          </div>
        )
}
 
export default ApprovalModal;