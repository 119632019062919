import React, { useState, useEffect } from "react";
import System from "./System";
const Results = ({
	systems,
	approval,
	query,
	sysclicked,
	imagePreview,
	marketer,
}) => {
	const thesesystems = [...systems];
	let sys = [1, 2, 3, 4];
	let thesystems = thesesystems;
	if (!marketer) {
		thesystems = thesesystems.filter((systems) => {
			return systems.approval == approval;
		});
	}
	useEffect(() => {}, [systems]);
	return (
		<div className='results'>
			{thesystems.length < 1 &&
				sys.map((sys) => (
					<div className='single-system-skel' key={sys}>
						<div className='first-col-skel'></div>
						<div className='second-col-skel'></div>
					</div>
				))}
			{thesystems.length > 0 &&
				thesystems
					.filter((systems) => systems.marketer.toLowerCase().includes(query))
					.map((systems) => (
						<System
							key={systems._id}
							name={systems.marketer}
							doors={systems.sysDoors}
							systype={systems.systype}
							sysclicked={sysclicked}
							sysid={systems._id}
							approval={systems.approval}
							markCont={systems.markCont}
							imagePreview={imagePreview}
							link1={systems.link1}
							version={systems.version}
						/>
					))}
		</div>
	);
};

export default Results;
