import React, { useState, useEffect } from 'react';
import Button from '../../../../Button'
import { FcCancel,FcNext,FcPrevious } from "react-icons/fc";
import { BsExclamationTriangle } from "react-icons/bs";
import { IoChatbubbleOutline } from "react-icons/io5";

const SystemDetails = ({ system,updatesystem ,reloadsystems,user}) => {
	let setting 
	let systemid;
	const [artisanContact, setartisanContact] = useState(null)
	const [systemCost, setsystemCost] = useState(null)
	const [loanAmount, setloanAmount] = useState(null)
	const [doorsNum,setdoorsNum]=useState(null)
	const [showError, setshowError] = useState(false)
	function updateState(){
		setartisanContact(system.artCont)
		setsystemCost(system.totCost)
		setloanAmount(system.loanAmount)
		setdoorsNum(system.sysDoors)
	}
	function updateSystem(){
			if(parseInt(artisanContact)<100000000 || (parseInt(artisanContact)>199999999)){
				if(parseInt(artisanContact)<700000000 || (parseInt(artisanContact)>799999999)){
					setshowError(true)
				}else{
				let body={artisancontact:parseInt(artisanContact),systemcost:parseInt(systemCost),loanamount:loanAmount,newdoors:parseInt(doorsNum)}
				fetch('/api/v1/update/'+systemid,{
					method:"POST",
					headers:{
						"Content-Type":"application/json"
					},
					body:JSON.stringify(body)
				}).then(res=>res.json()).then(res=>{
					if(res.updated){
						setartisanContact(null)
						reloadsystems()
						
					}
				})
				}
			}
			
	}
	if(system){
		setting= system.setting;
		systemid=system._id
		if (system.setting == 'other_category') {
			setting = system.settingOther;
		} else if (system.setting == 'rental_property') {
			setting = 'Rental Property';
		}
		if (system.systype == 'vidp') {
			system.systype = 'Ventilated Improved Double Pit (VIDP)';
		} else if (system.systype == 'VIDP') {
			system.systype = 'Ventilated Improved Double Pit (VIDP)';
		} else if (system.systype == 'visp') {
			system.systype = 'Ventilated Improved Single Pit';
		} else if (system.systype == 'double_leach_pit') {
			system.systype = 'Double Leach Pit';
		} else if (system.systype == 'septic_tank') {
			system.systype = 'Septic Tank';
		} else if (system.systype == 'rehabilitation') {
			system.systype = system.rehabMod;
		} else if (system.systype == 'condominium') {
			system.systype = 'Condominium Sewer';
		} else if (system.systype == 'flush_toilet_linked_to_main_se') {
			system.systype = 'Main Sewer Connection';
		}
		else if (system.systype == 'single_leach_pit') {
			system.systype = 'Single Leach Pit';
		}
		else if (system.systype == 'sato_pan') {
			system.systype = 'Sato Pan';
		}
		
	}
	useEffect(()=>{
		if(system){
			updateState()
		}
	},[system])
	useEffect(()=>{
		updateSystem()
	},[updatesystem])

	return (
		<div className='system-details'>
			{/* <div className='previous-toilet'>
				<h1 className='skip-button'><FcPrevious/></h1>
			</div>
			<div className='next-toilet'>
			<h1 className='skip-button'><FcNext/></h1>

			</div> */}
			{ system && 
			<div>				
			<h2>{system.systype}</h2>
			{system.approval !="Action Needed" && <div>
			<div className="sysdetails-columns">
				<div className="column1">
					<h3>
						{' '}
						Users: <strong>{system.sysUsers}</strong>
					</h3>
					<h3>
						{' '}
						Doors: <strong>{system.sysDoors}</strong>
					</h3>
					
					
					<div>
					<h3>
						{' '}
						Precision: <strong>{parseInt(system.precision)} meters</strong>
					</h3>
					<h3>
						{' '}
						Setting: <strong>{setting}</strong>
					</h3>
					<h3>
						{' '}
						Status: <strong>{system.completion}</strong>
					</h3>
					</div> 
					<h3>
						{' '}
						Cost: <strong>{user.user.country=="Kenya"? "Ksh" : "Ush"} {system.totCost}</strong>
					</h3>
				</div>
				<div className="column2">
					<h3>
						Sub County: <strong>{system.scounty}</strong>
					</h3>
					<h3>
						Ward: <strong>{system.ward || system.parish}</strong>
					</h3>
					<h3 className='markrole'>Marketer type: <strong>{system.role}</strong></h3>
					{system.approval=='Pending' && <div className='approval-comment'>
						<div className='approval-icon'>
							<BsExclamationTriangle className='approval-comment-icon'/>
						</div>
						<div className='approval-comment-thecomment'>
							<p>{system.pendReason}</p>
						</div>
					</div>}
					{system.approval=='Rejected' && <div className='approval-comment-rejected'>
						<div className='approval-icon'>
							<BsExclamationTriangle className='approval-comment-icon-reject'/>
						</div>
						<div className='approval-comment-thecomment'>
							<p>{system.rejectReason}</p>
						</div>
					</div>}
					{system.approval=='Not Verified' && <div className='approval-comment-notverified'>
						<div className='approval-icon'>
							<IoChatbubbleOutline className='approval-comment-icon-nv'/>
						</div>
						<div className='approval-comment-thecomment'>
							<p>{system.reportingComment || 'No Comment from Marketer'}</p>
						</div>
					</div>}
				</div>

			</div>

			</div>}
			{
				system.approval=="Action Needed" && 
				<div className="sysdetails-columns">
					<div className="column1">
					<div className='action-warning'>
					
					{system.approvalComment.map(comment=>(<h3><FcCancel/>{comment}</h3>))}
					</div>
					
					</div>
					<div className="column2action">
					{/* <h3>
						{' '}
						Client Name: <strong>{system.client}</strong>
					</h3>
					{system.susArtCont && <h3>
						{' '}
						Artisan Contact: <input className='action-input' type="text" placeholder={`0${artisanContact}`} onChange={e=>setartisanContact(e.target.value)}/>
					</h3>}					
					{system.susCost && <h3>
						{' '}
						Toilet cost: <input className='action-input' type="text" value={systemCost} onChange={e=>setsystemCost(e.target.value)}/>
					</h3>}
					{system.susLoan && <h3>
						{' '}
						Loan Amount: <input className='action-input' type="text" value={loanAmount} onChange={e=>setloanAmount(e.target.value)}/>
					</h3>}
					{system.suspectDoors && <h3>
						{' '}
						Doors: <input className='action-input' type="text" value={doorsNum} onChange={e=>setdoorsNum(e.target.value)}/>
					</h3>} */}
					</div>
				</div>
			}
						</div>
}
		</div>
	);
};

export default SystemDetails;
