import { useEffect,useState } from "react";
import VerificationImage from "../../Verification/VerificationContainers/Verification-Images/VerificationImage";
import { BiArrowBack } from "react-icons/bi";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  import { faker } from '@faker-js/faker';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const options = {
    responsive: true,
    plugins: {      
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };
  export const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderRadius: Number.MAX_VALUE
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderRadius: Number.MAX_VALUE

      },
    ],
  };
const SystemPreview = ({id,handleBack,marketer}) => {
    const [sys, setsys] = useState(null)
    const [mark,setmark]=useState(null)
    function loadInfo(){
        if(id){
            fetch('/api/v1/onesystem/'+id).then(data=>data.json()).then(data=>{
                setsys(data.system[0])
            })            
        }
        else if(marketer){
            setmark(true)
        }
    }
    useEffect(()=>{
        loadInfo()
    },[sys])
    useEffect(()=>{
        loadInfo()
    },[])
    return ( 
            <div className="sys-preview">
                <h1 className="back-arrow" onClick={e=>handleBack()}><BiArrowBack/></h1>
                {sys && <div className="sys-preview-image"> 
                <h3>Image Preview.</h3>                   
                 <VerificationImage system={sys} preview={true}/>
                </div>}
                {mark && <div className="sys-preview-image">
                    <div className="graphs-preview">
                    <Bar options={options} data={data}/>
                    </div>
                    </div>}
            </div>
        );
}
 
export default SystemPreview;