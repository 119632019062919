import React, { useState, useEffect } from 'react';
import { AiFillWarning } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";


const Indicator = ({ ver, indicatorclick,systems,user}) => {
	let title = ver;
	let classname;
	let systype;
	switch (title) {
		case 'Not Verified':
			classname = 'ver-indicator-verification';
			systype = 'notverified';
			break;
		case 'Pending':
			classname = 'ver-indicator-pending';
			systype = 'pending';
			break;
		case 'Approved':
			classname = 'ver-indicator-approved';
			systype = 'approved';
			break;
		case 'Rejected':
			classname = 'ver-indicator-rejected';
			systype = 'rejected';
			break;
		case 'Action Needed':
			classname = 'ver-indicator-action';
			systype = 'action';

		default:
			//    classname="ver-indicator-verification"
			break;
	}
	useEffect(()=>{
		return
	},[systems])
	return (
		<a onClick={() => indicatorclick(ver)} className="ver-indicator-link">
			<div className='ver-indicator'>
				<div className={classname}>
					<h4 className='ver-indicator-text'>{user.isAuth ? title : 'Reported'}</h4>
					<div className='ver-indicator-number'>
						{systems && systems[systype] && <h4 className='ver-indicator-number-actual'>{systems[systype]}</h4>}
						{systems && !systems[systype] && systype=='rejected' && <h4 className='ver-indicator-number-actual'><AiFillWarning/></h4>}
						{systems && !systems[systype] && systype=='approved' && <h4 className='ver-indicator-number-actual'><FcCheckmark/></h4>}
					</div>
				</div>
			</div>
		</a>
	);
};
export default Indicator;
